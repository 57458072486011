import { IonContent, IonPage, IonActionSheet, IonText, IonPopover, IonIcon, IonButtons, IonButton, IonToolbar, IonTitle } from '@ionic/react';
import React, {useCallback, useState, useEffect} from 'react';
// import { close } from 'ionicons/icons';
// import { isPlatform } from '@ionic/react';
import '../LocalQuotes.scss';
import { useDispatch, useSelector } from 'react-redux';
import * as uiActions from '../../../../store/reducers/ui';
import * as qqActions from '../../../../store/reducers/dashboard/qq';
import CoreService from '../../../../shared/services/CoreService';
import QuotationContentArchive from './QuotationContentArchive'; 
import ListSkeleton from '../../../../components/Skeleton/ListSkeleton';
import NoData from '../../../../components/Common/NoData';
import { nanoid } from 'nanoid';

const MyQuotationArchive: React.FC = () => {
  const dispatch = useDispatch();
  const skeleton = useSelector( (state:any) => state.ui.skeleton);
  const authUser = useSelector( (state:any) => state.auth.data.user);
  const qts = useSelector( (state:any) => state.qq.quotations);
  const [showActionSheet, setShowActionSheet] = useState<any>({status: false, qq: null});
  // const [showPopover, setShowPopover] = useState<any>({status: false, qq: null});
  let actionsheetButtons: any = [];

  const onCallbackFn = useCallback((res: any) => {
    if(res.status === 'SUCCESS'){
      dispatch(qqActions.setSQs({ data: res.data }));
    }
    dispatch(uiActions.setShowSkeleton({ skeleton: false }));
  }, [dispatch]);

  useEffect(() => { 
    if( authUser && authUser.ID ){
      dispatch(uiActions.setShowSkeleton({ skeleton: true }));
      // dispatch(uiActions.setShowLoading({ loading: true }));
      const data = {
        action: 'get_seller_quotations_archive',
        memID: authUser.ID,
        repID: authUser.repID
      };
      CoreService.onPostFn('qq_update', data, onCallbackFn);
    }
  }, [dispatch, onCallbackFn, authUser]); 

  return (
    <IonPage className="rfq-page mb-4">
      <IonToolbar>
        <IonTitle className="page-title">My Quotations Archive</IonTitle>
      </IonToolbar>
      { !skeleton.showSkeleton && qts ? ( 
        <IonContent>
          { qts.map((qt: any, index: number) => {
            return (<QuotationContentArchive 
              qt={qt} 
              key={nanoid()} 
              // setShowActionSheet={setShowActionSheet} 
              // setShowPopover={setShowPopover}
              // setShowDeleteModal={setShowDeleteModal}
              />)
          })} 
          <NoData dataArr={qts} htmlText={`No archive found.`} />
          
          <IonActionSheet
            isOpen={showActionSheet.status}
            onDidDismiss={() => setShowActionSheet({status: false, qq: null})}
            cssClass='my-custom-class'
            buttons={actionsheetButtons}
          >
        </IonActionSheet>
          
      </IonContent>) : ( <ListSkeleton /> )}

    </IonPage>
  );
};

export default MyQuotationArchive;

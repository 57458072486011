import React, {useState, useCallback, useEffect} from 'react';
import { IonHeader,
  IonToolbar,
  IonButton,
  IonIcon,
  IonLabel,
  IonContent, 
  IonList, 
  IonItem,
  IonAvatar,
  IonText,
  IonMenuToggle,
  IonItemGroup,
  IonListHeader,
  useIonAlert
} from '@ionic/react';
import { 
  peopleOutline,
  personOutline,
  speedometer,
  logOut,
  chevronUpOutline,
  chevronDownOutline,
  businessOutline,
  personAddOutline,
  listOutline,
  addOutline,
  newspaperOutline,
  fileTrayFullOutline,
  documentOutline,
  documentTextOutline,
  musicalNotesOutline,
  videocamOutline,
  archiveOutline,
  listCircleOutline,
  notificationsCircleOutline,
  trashOutline,
  imageOutline
} from 'ionicons/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import './Header.scss';
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from '../../store/reducers/auth';
import { lfConfig } from '../../../Constants';
import * as uiActions from '../../store/reducers/ui';
import * as repActions from '../../store/reducers/dashboard/rep';
import CoreService from '../../shared/services/CoreService';

interface Props{
    removeOverlay: Function
}

const RightMenu: React.FC<Props> = ({removeOverlay}) => {
    let repNotify = false;
    let comNotify = false;
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const authValues = useSelector( (state:any) => state.auth.data);  // console.log(authValues);
    const menuItems = useSelector( (state:any) => state.auth.menu); // console.log(menuItems);
    const memOpts = useSelector( (state:any) => state.auth.memOptions);
    const comProfile = useSelector( (state:any) => state.rep.comProfile);
    
    const { basename } = lfConfig;
    const [showRepProfile, setShowRepProfile] = useState(false); 
    const [showPressRelease, setShowPressRelease] = useState(false);
    const [showResource, setShowResource] = useState(false);
    const [showLocalDeal, setShowLocalDeal] = useState(false);
    const [showAds, setShowAds] = useState(false);
    const [showBusinessQQ, setShowBusinessQQ] = useState(false);
    const reps = useSelector( (state:any) => state.rep.reps);
    const [presentAlert] = useIonAlert();

    if(reps && reps.length > 0){
      repNotify =  reps.every((item: any) => {
        return (!item.address1 || !item.country || !item.phone || !item.profile_description)
      });
      comNotify = comProfile && (!comProfile.address1 || !comProfile.country || !comProfile.description) && authValues && authValues.user && authValues.user.accType === 'full' && [1,2,3].includes(+(comProfile.mem_level));
    }
    // console.log(repNotify, comNotify);

    const onCallbackFn = useCallback((res: any) => {
      if(res.status === 'SUCCESS'){
        dispatch(repActions.setMemberProfile({ data: res.data.user }));
      }
      dispatch(uiActions.setShowLoading({ loading: false }));
    }, [dispatch]);
  
    useEffect(() => {  
      if( authValues && authValues.user && +(authValues.user.mem_level) !== 0 ){
        dispatch(uiActions.setShowLoading({ loading: true }));
        const data = {
          action: 'get_member',
          memID: authValues.user.ID,
          repID: authValues.user.repID
        };
        CoreService.onPostFn('get_member', data, onCallbackFn);
      }
    }, [dispatch, onCallbackFn, authValues]);

    function logout(e: any){
        removeOverlay(e);
        dispatch(authActions.logout({ logout: true }));
        navigate("/login");
    }

    return (<>
      { authValues &&  authValues.user &&
        <>
          <IonHeader>
            <IonToolbar className="pt-0">
              <IonItem color="blackbg">
                <IonAvatar className="user-avatar md hydrated" slot="start">
                  <img src={`${basename}/assets/img/avatar.svg`} alt="Avatar" title="Avatar" />
                </IonAvatar>
                <IonLabel>
                  <IonText>
                    <h2><strong>{authValues.user.firstname+" "+authValues.user.lastname}</strong></h2>
                  </IonText>
                  <IonText><h3>
                    {authValues.user.business_type === 'Seller'? 'Provider': authValues.user.business_type}
                    {`${authValues.user.accType==='sub'? ' (Sub)': ' (Primary)'}`}</h3></IonText>
                  
                  <IonButton color="appbg" onClick={(e) => logout(e)}>
                    <IonIcon slot="icon-only" icon={logOut}></IonIcon>
                    <IonLabel>Logout</IonLabel>
                  </IonButton>
                </IonLabel>
              </IonItem>
            </IonToolbar>
          </IonHeader>
          { authValues.authenticated && authValues.isVerified && <IonContent>
            <IonList className="ion-no-padding">
              <IonItem className="cursor" button color={location.pathname === '/layout/dashboard'? 'menuhlbg': 'blackbg'} routerLink={`/layout/dashboard`} onClick={ (e) => removeOverlay(e) }>
                  <IonIcon className="mr-3" slot="start" icon={speedometer}></IonIcon>
                <IonLabel>Dashboard</IonLabel>
              </IonItem>
              { authValues.user.accType === 'full' && memOpts && ([1,3].includes(parseInt(memOpts.profile))) === true  &&
                <IonItem className="cursor" button color={location.pathname.includes('/layout/company-profile')? 'menuhlbg': 'blackbg'} routerLink={`/layout/company-profile`} onClick={ (e) => removeOverlay(e) }>
                  <IonIcon className="mr-3" slot="start" icon={businessOutline}></IonIcon>
                  <IonLabel>Company Profile</IonLabel>
              </IonItem>
              }
              { memOpts && ([2,3].includes(parseInt(memOpts.profile))) === true  && 
              <IonMenuToggle auto-hide="false" >
                <IonItem className="cursor" button color={location.pathname.includes('/layout/rep-profile/')? 'menuhlbg': 'blackbg'} onClick={ (e) => setShowRepProfile(!showRepProfile) }>
                    <IonIcon className="mr-3" slot="start" icon={peopleOutline}></IonIcon>
                    <IonIcon className="hidden-xs hidden-sm" slot="end" icon={showRepProfile ? chevronUpOutline : chevronDownOutline}></IonIcon>
                    <IonLabel>{ parseInt(memOpts.profile) === 2? 'Profile': 'Rep Profile'}</IonLabel>
                </IonItem>
                { (menuItems && showRepProfile) && 
                  <IonItemGroup className="custom-list-sidemenu">
                    { authValues.user.accType === 'full' && memOpts && [3].includes(parseInt(memOpts.profile)) === true && 
                    <IonItem className="cursor custom-list-item" button color="menu-sub-item" routerLink={`/layout/add-newrep`} onClick={ (e) => removeOverlay(e) }>
                        <IonIcon className="mr-2 fs-20" slot="start" icon={personAddOutline}></IonIcon>
                        <IonLabel>Add Rep Profile</IonLabel>
                    </IonItem>}
                    {  menuItems && menuItems.reps && menuItems.reps.map((item: any, index: number) => {
                        return <IonItem className="cursor custom-list-item" key={index} button color="menu-sub-item" routerLink={`/layout/rep-profile/${item.mem_id}/${item.rep_id}`} onClick={ (e) => removeOverlay(e) }>
                        <IonIcon className="mr-2 fs-20" slot="start" icon={personOutline}></IonIcon>
                        {/* {!item.is_updated  && 
                          <IonIcon slot="end" color="warning" icon={alertCircleOutline}></IonIcon>
                        } */}
                        <IonLabel>{item.firstname}</IonLabel>
                      </IonItem>
                    })}
                  </IonItemGroup>}
                </IonMenuToggle>}  
                { ([1,2,3].includes(+(authValues.user.mem_level)) ) && menuItems?.menuOpts?.some((value: any) => (value.menu_key === 'press_release' && value.menu_value === 'yes')) // 
                    && ((memOpts && [3].includes(parseInt(memOpts.profile)) === true && (!repNotify && !comNotify))
                    || ( memOpts && [2].includes(parseInt(memOpts.profile)) === true && !repNotify)
                ) &&
                <IonMenuToggle auto-hide="false" >
                  <IonItem className="cursor" button color={['/layout/add-press-release/', '/layout/press-releases/'].includes(location.pathname)? 'menuhlbg': 'blackbg'} onClick={ (e) => setShowPressRelease(!showPressRelease) }>
                      <IonIcon className="mr-3" slot="start" icon={newspaperOutline}></IonIcon>
                      <IonIcon className="hidden-xs hidden-sm" slot="end" icon={showPressRelease ? chevronUpOutline : chevronDownOutline}></IonIcon>
                      <IonLabel>Press Release</IonLabel>
                  </IonItem>
                  { (menuItems && showPressRelease) && 
                  <IonItemGroup className="custom-list-sidemenu"> 
                    <IonItem className="cursor custom-list-item" button color="menu-sub-item" routerLink={`/layout/add-press-release/`} onClick={ (e) => removeOverlay(e) }>
                        <IonIcon className="mr-2 fs-20" slot="start" icon={addOutline}></IonIcon>
                        <IonLabel>Add Press Release</IonLabel>
                    </IonItem>
                    <IonItem className="cursor custom-list-item" button color="menu-sub-item" routerLink={`/layout/press-releases/`} onClick={ (e) => removeOverlay(e) }>
                        <IonIcon className="mr-2 fs-20" slot="start" icon={listOutline}></IonIcon>
                        <IonLabel>Press Release Listing</IonLabel>
                    </IonItem>
                  </IonItemGroup>}
                </IonMenuToggle>}
                  
                { ([1,2,3].includes(+(authValues.user.mem_level)) ) && menuItems?.menuOpts?.some((value: any) => (value.menu_key === 'resources' && value.menu_value === 'yes')) 
                    && ((memOpts && [3].includes(parseInt(memOpts.profile)) === true && (!repNotify && !comNotify))
                    || ( memOpts && [2].includes(parseInt(memOpts.profile)) === true && !repNotify)
                ) && 
                <IonMenuToggle auto-hide="false" >
                  <IonItem className="cursor" button color={['/layout/my-uploads/', '/layout/add-resource/document/', '/layout/resources/video/', '/layout/resources/audio/', '/layout/resources/document/'].includes(location.pathname)? 'menuhlbg': 'blackbg'} onClick={ (e) => setShowResource(!showResource) }>
                      <IonIcon className="mr-3" slot="start" icon={fileTrayFullOutline}></IonIcon>
                      <IonIcon className="hidden-xs hidden-sm" slot="end" icon={showResource ? chevronUpOutline : chevronDownOutline}></IonIcon>
                      <IonLabel>My Uploads</IonLabel>
                  </IonItem>
                  { (menuItems && showResource) && 
                  <IonItemGroup className="custom-list-sidemenu">
                    <IonItem className="cursor custom-list-item" button color="menu-sub-item" routerLink={`/layout/add-resource/document/`} onClick={ (e) => removeOverlay(e) }>
                        <IonIcon className="mr-2 fs-20" slot="start" icon={addOutline}></IonIcon>
                        <IonLabel>Add Document</IonLabel>
                    </IonItem>
                    <IonItem className="cursor custom-list-item" button color="menu-sub-item" routerLink={`/layout/resources/video/`} onClick={ (e) => removeOverlay(e) }>
                        <IonIcon className="mr-2 fs-20" slot="start" icon={videocamOutline}></IonIcon>
                        <IonLabel>Video</IonLabel>
                    </IonItem>
                    <IonItem className="cursor custom-list-item" button color="menu-sub-item" routerLink={`/layout/resources/audio/`} onClick={ (e) => removeOverlay(e) }>
                        <IonIcon className="mr-2 fs-20" slot="start" icon={musicalNotesOutline}></IonIcon>
                        <IonLabel>Audio</IonLabel>
                    </IonItem>
                    <IonItem className="cursor custom-list-item" button color="menu-sub-item" routerLink={`/layout/resources/document/`} onClick={ (e) => removeOverlay(e) }>
                        <IonIcon className="mr-2 fs-20" slot="start" icon={documentOutline}></IonIcon>
                        <IonLabel>Documents</IonLabel>
                    </IonItem>
                    <IonItem className="cursor custom-list-item" button color="menu-sub-item" routerLink={`/layout/resources/article/`} onClick={ (e) => removeOverlay(e) }>
                        <IonIcon className="mr-2 fs-20" slot="start" icon={documentTextOutline}></IonIcon>
                        <IonLabel>Articles</IonLabel>
                    </IonItem>
                  </IonItemGroup>}
                </IonMenuToggle>}
                { ([1,2,3].includes(+(authValues.user.mem_level)) ) && menuItems?.menuOpts?.some((value: any) => (value.menu_key === 'local_deals' && value.menu_value === 'yes')) 
                    && ((memOpts && [3].includes(parseInt(memOpts.profile)) === true && (!repNotify && !comNotify))
                    || ( memOpts && [2].includes(parseInt(memOpts.profile)) === true && !repNotify)
                ) && 
                <IonMenuToggle auto-hide="false" >
                  <IonItem className="cursor" button color={['/layout/deals/local-deal','/layout/deals/add-deal', '/layout/deals/local-deals'].includes(location.pathname)? 'menuhlbg': 'blackbg'} onClick={ (e) => setShowLocalDeal(!showLocalDeal) }>
                      <IonIcon className="mr-3" slot="start" icon={newspaperOutline}></IonIcon>
                      <IonIcon className="hidden-xs hidden-sm" slot="end" icon={showLocalDeal ? chevronUpOutline : chevronDownOutline}></IonIcon>
                      <IonLabel>Local Deals</IonLabel>
                  </IonItem>
                  { menuItems && showLocalDeal  &&
                  <IonItemGroup className="custom-list-sidemenu"> 
                    { (+(memOpts.localdeals.total) < +(memOpts.localdeals.no_free_deals)) && 
                    <IonItem className="cursor custom-list-item" button color="menu-sub-item" routerLink={`/layout/deals/add-deal/`} onClick={ (e) => removeOverlay(e) }>
                        <IonIcon className="mr-2 fs-20" slot="start" icon={addOutline}></IonIcon>
                        <IonLabel>Add Deal</IonLabel>
                    </IonItem> }
                    { (+(memOpts.localdeals.total) >= +(memOpts.localdeals.no_free_deals)) && 
                      <IonItem className="cursor custom-list-item" button color="menu-sub-item" routerLink={`/layout/deals/buy-deal/`} onClick={ (e) => removeOverlay(e) }>
                        <IonIcon className="mr-2 fs-20" slot="start" icon={addOutline}></IonIcon>
                        <IonLabel>Purchase a Deal</IonLabel>
                    </IonItem> }
                    <IonItem className="cursor custom-list-item" button color="menu-sub-item" routerLink={`/layout/deals/local-deals`} onClick={ (e) => removeOverlay(e) }>
                        <IonIcon className="mr-2 fs-20" slot="start" icon={listOutline}></IonIcon>
                        <IonLabel>Local Deals Listing</IonLabel>
                    </IonItem>
                  </IonItemGroup>}
                </IonMenuToggle>}

                { [1,2,3].includes(+(authValues.user.mem_level)) 
                    && ((memOpts && [3].includes(parseInt(memOpts.profile)) === true && (!repNotify && !comNotify))
                    || ( memOpts && [2].includes(parseInt(memOpts.profile)) === true && !repNotify)
                ) && 
                <IonMenuToggle auto-hide="false" >
                  <IonItem className="cursor" button color={['/layout/ads/sponsor-ads','/layout/ads/create-ad', '/layout/ads/buy-ads'].includes(location.pathname)? 'menuhlbg': 'blackbg'} onClick={ (e) => setShowAds(!showAds) }>
                      <IonIcon className="mr-3" slot="start" icon={imageOutline}></IonIcon>
                      <IonIcon className="hidden-xs hidden-sm" slot="end" icon={showAds ? chevronUpOutline : chevronDownOutline}></IonIcon>
                      <IonLabel>Sponsor Ads</IonLabel>
                  </IonItem>
                  { menuItems && showAds  &&
                  <IonItemGroup className="custom-list-sidemenu">
                    <IonItem className="cursor custom-list-item" button color="menu-sub-item" routerLink={`/layout/ads/buy-ads/`} onClick={ (e) => removeOverlay(e) }>
                        <IonIcon className="mr-2 fs-20" slot="start" icon={addOutline}></IonIcon>
                        <IonLabel>Purchase an Ad</IonLabel>
                    </IonItem>
                    {/* <IonItem className="cursor custom-list-item" button color="menu-sub-item" routerLink={`/layout/ads/create-ad/`} onClick={ (e) => removeOverlay(e) }>
                        <IonIcon className="mr-2 fs-20" slot="start" icon={addOutline}></IonIcon>
                        <IonLabel>Create an Ad</IonLabel>
                    </IonItem> */}
                    <IonItem className="cursor custom-list-item" button color="menu-sub-item" routerLink={`/layout/ads/sponsor-ads`} onClick={ (e) => removeOverlay(e) }>
                        <IonIcon className="mr-2 fs-20" slot="start" icon={listOutline}></IonIcon>
                        <IonLabel>Ads Listing</IonLabel>
                    </IonItem>
                  </IonItemGroup>}
                </IonMenuToggle>}
                

                { menuItems?.menuOpts?.some((value: any) => (value.menu_key === 'get_quotes' && value.menu_value === 'yes')) 
                    && ((memOpts && [3].includes(parseInt(memOpts.profile)) === true && (!repNotify && !comNotify))
                    || ( memOpts && [2].includes(parseInt(memOpts.profile)) === true && !repNotify)
                ) && 
                <IonMenuToggle auto-hide="false" >
                  <IonItem className="cursor" button color={['/layout/add-localquote', '/layout/buyer-request-center', '/layout/my-localquotes-archive'].includes(location.pathname)? 'menuhlbg': 'blackbg'} onClick={ (e) => setShowBusinessQQ(!showBusinessQQ) }>
                      <IonIcon className="mr-3" slot="start" icon={fileTrayFullOutline}></IonIcon>
                      <IonIcon className="hidden-xs hidden-sm" slot="end" icon={showBusinessQQ ? chevronUpOutline : chevronDownOutline}></IonIcon>
                      <IonLabel>Quotes</IonLabel>
                  </IonItem>
                  { (menuItems && showBusinessQQ  ) && <> {/* && authValues.user.accType === 'full' */}
                  <IonItemGroup className="custom-list-sidemenu">
                    <IonListHeader className="custom-list-header">
                      Get Quotes
                    </IonListHeader>
                    <IonItem className="cursor custom-list-item" color="menu-sub-item" button routerLink={`/layout/add-localquote/`} onClick={ (e) => removeOverlay(e) }>
                        <IonIcon className="mr-2 fs-20" slot="start" icon={addOutline}></IonIcon>
                        <IonLabel>Submit RFQ</IonLabel>
                    </IonItem>
                    <IonItem className="cursor custom-list-item" color="menu-sub-item" button routerLink={`/layout/buyer-request-center`} onClick={ (e) => removeOverlay(e) }>
                        <IonIcon className="mr-2 fs-20" slot="start" icon={listOutline}></IonIcon>
                        <IonLabel>My RFQ's</IonLabel>
                    </IonItem>
                    <IonItem className="cursor custom-list-item" color="menu-sub-item" button routerLink={`/layout/my-localquotes-archive`} onClick={ (e) => removeOverlay(e) }>
                        <IonIcon className="mr-2 fs-20" slot="start" icon={archiveOutline}></IonIcon>
                        <IonLabel>My Archive</IonLabel>
                    </IonItem>
                  </IonItemGroup>
                  </>}
                  { showBusinessQQ && ([1,2].includes(+(authValues.user.mem_level)) )  && menuItems?.menuOpts?.some((value: any) => (value.menu_key === 'submit_quotes' && value.menu_value === 'yes')) && // authValues.user.accType === 'full' && 
                  <IonItemGroup className="custom-list-sidemenu">  
                    <IonListHeader className="custom-list-header">
                      Submit Quotes
                    </IonListHeader>
                    <IonItem className="cursor custom-list-item" button color="menu-sub-item" routerLink={`/layout/seller-request-center`} onClick={ (e) => removeOverlay(e) }>
                        <IonIcon className="mr-2 fs-20" slot="start" icon={listOutline}></IonIcon>
                        <IonLabel>View Requests</IonLabel>
                    </IonItem>
                    <IonItem className="cursor custom-list-item" button color="menu-sub-item" routerLink={`/layout/my-quotations`} onClick={ (e) => removeOverlay(e) }>
                        <IonIcon className="mr-2 fs-20" slot="start" icon={listCircleOutline}></IonIcon>
                        <IonLabel>Quotes Sent</IonLabel>
                    </IonItem>
                    <IonItem className="cursor custom-list-item" button color="menu-sub-item" routerLink={`/layout/my-quotations-archive`} onClick={ (e) => removeOverlay(e) }>
                        <IonIcon className="mr-2 fs-20" slot="start" icon={archiveOutline}></IonIcon>
                        <IonLabel>Quotes Archive</IonLabel>
                    </IonItem>
                    
                  </IonItemGroup>}
                  
                </IonMenuToggle>}
                <IonItem className="cursor" button color={['/layout/notification-settings'].includes(location.pathname)? 'menuhlbg': 'blackbg'} routerLink={`/layout/notification-settings`} onClick={ (e) => removeOverlay(e) }>
                    <IonIcon className="mr-3" slot="start" icon={notificationsCircleOutline}></IonIcon>
                    <IonLabel>Notify Settings</IonLabel>
                </IonItem>
                <IonItem className="cursor" button color={['/layout/rep-profile-delete'].includes(location.pathname)? 'menuhlbg': 'blackbg'}  onClick={() =>
                      presentAlert({
                        header: `Delete Account`,
                        subHeader: 'Are you sure to delete your account?',
                        cssClass: 'custom-alert',
                        buttons: [
                          {
                            text: 'No',
                            cssClass: 'alert-button-cancel',
                            handler: () => {
                              console.log('This is cancel');
                            }
                          },
                          {
                            text: 'Yes',
                            cssClass: 'alert-button-confirm',
                            handler: () => {
                              navigate("/layout/rep-profile-delete");
                              removeOverlay();
                            }
                          },
                        ],
                      })}>
                    <IonIcon className="mr-3" slot="start" icon={trashOutline}></IonIcon> {/* routerLink={`/layout/rep-profile-delete`} */}
                    <IonLabel>Delete Account</IonLabel>
                </IonItem>
            </IonList>
          </IonContent> }
        </>
      }
    </>);
}



export default RightMenu;

import { createSlice } from '@reduxjs/toolkit';
let state = {
    ad: {},
    ads: []
}

const slice = createSlice({
    name: 'ads',
    initialState: state,
    reducers: {
        
        setAd: (adState, action) => {
            if( action.payload.data ){
                const data = action.payload.data;
                adState.ad = data;
            }
        },
        setAds: (adState, action) => {
            if( action.payload.data ){
                const data = action.payload.data;
                adState.ads = data;
            }
        },
    }
});

export const {setAd, setAds} = slice.actions;
export default slice.reducer;
import { IonCardHeader, IonCardSubtitle, IonRouterLink } from '@ionic/react';
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import {shuffle} from 'lodash';
import CoreService from '../../shared/services/CoreService';
import * as commonActions from '../../store/reducers/common';
import * as uiActions from '../../store/reducers/ui';
interface PropsInterface{
    limit: number
}

const PatnerAds: React.FC<PropsInterface> = ({limit}) => { 
    const dispatch = useDispatch();
    let adResults = useSelector( (state:any) => state.formdata.ads); 
    const location = useSelector( (state:any) => state.auth.location);
    adResults = shuffle(adResults);
    if(limit && limit > 0){
        adResults = adResults.slice(0,limit);
    }
    const onCbAdsFn = useCallback((res: any) => {
        if(res.status === 'SUCCESS'){ 
            dispatch(commonActions.setAds({ ads: res.data }));
        }else{
            dispatch(uiActions.setShowToast({ isShow: true, status: res.status, message: res.message }));
        }
    }, [dispatch]);
    useEffect(() => { 
        const data = {
        action: 'ads',
            geolocation: location
        };
        CoreService.onPostFn('search', data, onCbAdsFn);
    }, [onCbAdsFn]);

    return (<>
        {adResults && adResults.length > 0 && (adResults).map((adItem: any) => {
           return (<div className="aditem" key={nanoid()} >
               <IonRouterLink href={adItem.banner_link} target="_blank">
                    <IonCardHeader className="mediaheader">
                        <IonCardSubtitle className="ion-text-left ion-text-capitalize">Local First Sponsor</IonCardSubtitle>
                    </IonCardHeader>
                    <div className="ion-no-padding text-center">
                        <img src={adItem.image} alt="Banner Ads" />
                    </div>
            </IonRouterLink>
          </div>)} 
        )}
    </>);
};

export default memo(PatnerAds);

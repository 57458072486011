import {
    IonRouterLink, IonIcon
  } from '@ionic/react';
import React from "react";
import './Notification.scss';
import { close } from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux';
import CoreService from '../../../shared/services/CoreService';
import * as repActions from '../../../store/reducers/dashboard/rep';
import * as qqActions from '../../../store/reducers/dashboard/qq';
import * as uiActions from '../../../store/reducers/ui';
import { lfConfig } from '../../../../Constants';
import { nanoid } from '@reduxjs/toolkit';
  
const NotifyContent: React.FC = () => {
    const dispatch = useDispatch();
    // const { basename, apiBaseURL } = lfConfig;
    const authUser = useSelector( (state:any) => state.auth.data.user);
    const conversations = useSelector( (state:any) => state.rep.notifications.conversations); // console.log(conversations);
    const newQuotations = useSelector( (state:any) => state.rep.notifications.newQuotations);

    const setViewStatusFn = (item: any) => {
      // console.log(authUser);
      console.log(item);
      let data = {
        action: 'conv_message_view_update',
        qq_type: item.type,
        bid: item.bid,
        brep_id: item.brep_id,
        bmem_id: item.bmem_id,
        sid: item.sid,
        srep_id: item.srep_id,
        smem_id: item.smem_id,
        memID: authUser.ID, // Auth memID
        repID: authUser.repID, // Auth repID
        prepID: authUser.prepID // Auth prepID
      };
      dispatch(uiActions.setShowLoading({ loading: true }));
      CoreService.onPostsFn('qq_message', data).then((res:any) => {
          if(res.data.status === 'SUCCESS'){ // console.log(res.data)
              dispatch(qqActions.setMessages({ data: res.data.data }));
              dispatch(repActions.setNotifications({ data: res.data.notifications }));
          }
          dispatch(uiActions.setShowLoading({ loading: false }));
      }).catch((error) => {
          dispatch(uiActions.setShowLoading({ loading: false }));
          dispatch(uiActions.setShowToast({ isShow: true, status: 'ERROR', message: error.message }));
      });
    }
    
    return (<>
        <ul>
          {conversations && conversations.length > 0 && conversations.map((item: any) => {
             
              let link: any;
              if( item.type === 'seller' ){ // You need to pass opposite link
                  link = <IonRouterLink color="blackbg" href={`/layout/buyer-request-center`}>{`You've got a new message for your LocalQuote - ${item.p_title}`}</IonRouterLink>;
              }else if(item.type === 'buyer' && +(item.sid) === 0){
                  link = <IonRouterLink color="blackbg" href={`/layout/seller-request-center`}>{`You've got a new message from the LocalQuote - ${item.p_title}`}</IonRouterLink>;
              }else{
                link = <IonRouterLink color="blackbg" href={`/layout/my-quotations`}>{`You've got a new message from the LocalQuote - ${item.p_title}`}</IonRouterLink>;
            }
              return <li key={nanoid()} className="d-flex justify-content-between align-items-center">
                {link}
                <span className='cursor ' onClick={() => setViewStatusFn(item)}><IonIcon icon={close} size="small"></IonIcon></span>
                </li>; 
          })}
          { newQuotations && newQuotations.length > 0 && newQuotations.map((item: any) => { // 2/52/3/1591
            return <li>
              <IonRouterLink color="blackbg" href={`/layout/view-quotation/${item.id}/${item.mem_id}/${item.bq_id}/${item.bmem_id}/buyer`}>You've got a new Quotation - <b>{item.s_title}</b> for your LocalQuote - <b>{item.p_title}</b></IonRouterLink>
              
            </li>;
          })}
        </ul>
    </>);
};
  
export default React.memo(NotifyContent);
  
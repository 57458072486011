import { IonCard, IonCardContent, IonContent, IonCardHeader, IonPage, IonCardTitle, IonGrid, IonRow, IonCol, IonItem, IonLabel, IonInput, IonText, IonButton } from '@ionic/react';
import React, {useCallback, useEffect} from 'react';
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import CoreService from '../../../shared/services/CoreService';
import './RepProfile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as authActions from '../../../store/reducers/auth';
import * as repActions from '../../../store/reducers/dashboard/rep';
import * as uiActions from '../../../store/reducers/ui';
import Breadcrumbs from '../../../components/Header/Breadcrumbs';

type FormInputsSingle = {
    confirm_text_single: string;
}
type FormInputsSub = {
    confirm_text_subs: string;
}
type FormInputsAll = {
    confirm_text_all: string;
}

const RepProfileDelete: React.FC = () => {
    let isSubReps = false;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authUser = useSelector( (state:any) => state.auth.data.user);
    const repProfile = useSelector( (state:any) => state.rep.repProfile);
    const menuItems = useSelector( (state:any) => state.auth.menu); // console.log(menuItems);
    let { repid, memid } = useParams<any>();

    let initialValuesSubs = {
        confirm_text_subs: ''
    };
    let initialValuesAll = {
        confirm_text_all: ''
    };
    let initialValuesSingle = {
        confirm_text_single: ''
    };
    const { control: subsControl, handleSubmit: handleSubsSubmit, formState: { errors: subsErrors } } = useForm<FormInputsSub>({
        defaultValues: { ...initialValuesSubs },
        mode: "onChange"
    });
    const { control: allControl, handleSubmit: handleAllSubmit, formState: { errors: allErrors } } = useForm<FormInputsAll>({
        defaultValues: { ...initialValuesAll },
        mode: "onChange"
    });
    const { control: singleControl, handleSubmit: handleSingleSubmit, formState: { errors: singleErrors } } = useForm<FormInputsSingle>({
        defaultValues: { ...initialValuesSingle },
        mode: "onChange"
    });

    const onCallbackFn = useCallback((res: any) => {
        if(res.status === 'SUCCESS'){
            dispatch(uiActions.setShowToast({ isShow: true, status: res.status, message: 'Profile deleted successfully.' }));
            dispatch(authActions.logout({ logout: true }));
            navigate("/login");
        }else{
            dispatch(uiActions.setShowToast({ isShow: true, status: res.status, message: res.message }));
        }
        dispatch(uiActions.setShowLoading({ loading: false }));
    }, [dispatch]);

    const onSubsSubmit = (data: any) => {
        dispatch(uiActions.setShowLoading({ loading: true }));
        const fd = {
            action: 'account_delete',
            deltype: 'Subs',
            memID: authUser.ID,
            repID: authUser.repID,
            confirm_text: data.confirm_text_subs
        };
        CoreService.onPostFn('member_update', fd, onCallbackFn);
    }
    const onAllSubmit = (data: any) => { 
        dispatch(uiActions.setShowLoading({ loading: true }));
        const fd = {
            action: 'account_delete',
            deltype: 'All',
            memID: authUser.ID,
            repID: authUser.repID,
            confirm_text: data.confirm_text_all
        };
        CoreService.onPostFn('member_update', fd, onCallbackFn);
    }
    const onSingleSubmit = (data: any) => { 
        dispatch(uiActions.setShowLoading({ loading: true }));
        const fd = {
            action: 'account_delete',
            deltype: 'Single',
            memID: authUser.ID,
            repID: authUser.repID,
            confirm_text: data.confirm_text_single
        };
        CoreService.onPostFn('member_update', fd, onCallbackFn);
    }
    const validateText = (str: string) => {
        if (str !== "PERMANENTLY DELETE") {
          return "Confirm Text should be PERMANENTLY DELETE"; // return error message if string is not valid
        }
    };

    // Check any active additional reps available or not
    if(menuItems && menuItems.reps){ 
        menuItems.reps.map((item: any, index: number) => {
            if(+(item.is_primary) !== 1){ 
                isSubReps = true;
            }
        })
    }
    console.log(isSubReps);

    return (
    <IonPage className="repprofile-page">
        <Breadcrumbs step1={{label:'Rep Profile Delete', link: ''}} />
        <IonContent>
            <IonRow>
                { isSubReps && <IonCol size='12' sizeMd="6">
                    { repProfile && Object.keys(repProfile).length > 0 && +(repProfile.is_primary) === 1 &&
                        <IonCard className="card-center mt-4 mb-3">
                            <IonCardHeader color="titlebg">
                                <IonCardTitle className="card-custom-title">Primary Representative profile deletion:</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <div className="p-2">
                                    <p>Are you sure you want to delete the primary representative profile? Please read how your profile deletion will affect.</p>
                                    <p className="fw-bold mt-2">Profile</p>
                                    <p>This is the primary representative profile that was set up when this admin account was opened. Deleting this profile will 
                                        remove all personal data related to this profile from our database. If you have submitted quotes to RFQ requests, those 
                                        quotes will be marked as removed. If you have submitted requests for quotations, the requests will be withdrawn and deleted. 
                                        The email used for this account will become permanently retained and the same email cannot be used to register a new account or sub account.</p>
                                    <p className="fw-bold mt-2">Subscription</p>
                                    <p>Deleting your account will unsubscribe this representative profile from all mailing lists. If you want to delete this account and all sub profiles</p>

                                    <p className="fw-bold mt-2">Confirm Account Deletion</p>
                                    <p>Enter <span className='fw-bold'>PERMANENTLY DELETE</span> to confirm</p>
                                </div>
                                <form onSubmit={handleSubsSubmit(onSubsSubmit)} autoComplete="off">
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="12" sizeXl="6">
                                                <IonItem className="ion-no-padding">
                                                    <IonLabel position="stacked">Confirm Text  <IonText color="danger">*</IonText></IonLabel>
                                                    <Controller 
                                                        name="confirm_text_subs"
                                                        control={subsControl}
                                                        render={({ field: {onChange, onBlur, value} }) => {
                                                            return <IonInput type="text"
                                                                onKeyUp={(e: any) => {
                                                                    var str = e.target.value;
                                                                    if( str.split(/\s+/).length > 10 ){
                                                                        e.target.value = str.split(/\s+/).slice(0, 10).join(" ");
                                                                    }
                                                                }} 
                                                                onIonChange={(e: any) => onChange(e.target.value)}
                                                                onBlur={onBlur}
                                                                value={value}
                                                            />
                                                        }}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: "Confirm Text is required"
                                                            },
                                                            validate: validateText
                                                        }}
                                                    />
                                                </IonItem>
                                                <ErrorMessage
                                                    errors={subsErrors}
                                                    name="confirm_text_subs"
                                                    render={({ message }) => <div className="invalid-feedback">{message}</div>}
                                                />
                                            </IonCol>

                                        </IonRow>
                                    </IonGrid>
                                    <IonButton color="appbg" className="ion-margin-top mt-4 float-right  mb-3" type="submit">
                                        Submit
                                    </IonButton>
                                </form>
                            </IonCardContent>
                        </IonCard>
                    }
                </IonCol>
                }
                <IonCol size='12' sizeMd="6">
                    { repProfile && Object.keys(repProfile).length > 0 && +(repProfile.is_primary) === 1 &&
                        <IonCard className="card-center mt-4 mb-3">
                            <IonCardHeader color="titlebg">
                                <IonCardTitle className="card-custom-title">Administration account deletion:</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <div className="p-2">
                                    <p>Are you sure you want to delete your account? Please read how your account deletion will affect.</p>
                                    <p className="fw-bold mt-2">Account and Profiles</p>
                                    <p>This is a primary administration account. Deleting this account will remove all company and personal 
                                        data as well as all data from representative profiles set up under this account from our database. 
                                        If the admin rep or any sub reps have submitted quotes to RFQ’s those quotes will be marked as removed. 
                                        If the admin rep or any sub reps have submitted requests for quotations the requests will be withdrawn and 
                                        deleted. The emails used to originally register the account and sub accounts will become permanently 
                                        retained and cannot be used to register any new accounts.</p>
                                    <p className="fw-bold mt-2">Subscription</p>
                                    <p>Deleting your account will unsubscribe you from all mailing lists.</p>

                                    <p className="fw-bold mt-2">Confirm Account Deletion</p>
                                    <p>Enter <span className='fw-bold'>PERMANENTLY DELETE</span> to confirm</p>
                                </div>
                                <form onSubmit={handleAllSubmit(onAllSubmit)} autoComplete="off">
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="12" sizeXl="6">
                                                <IonItem className="ion-no-padding">
                                                    <IonLabel position="stacked">Confirm Text  <IonText color="danger">*</IonText></IonLabel>
                                                    <Controller 
                                                        name="confirm_text_all"
                                                        control={allControl}
                                                        render={({ field: {onChange, onBlur, value} }) => {
                                                            return <IonInput type="text"
                                                                onKeyUp={(e: any) => {
                                                                    var str = e.target.value;
                                                                    if( str.split(/\s+/).length > 10 ){
                                                                        e.target.value = str.split(/\s+/).slice(0, 10).join(" ");
                                                                    }
                                                                }} 
                                                                onIonChange={(e: any) => onChange(e.target.value)}
                                                                onBlur={onBlur}
                                                                value={value}
                                                            />
                                                        }}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: "Confirm Text is required"
                                                            },
                                                            validate: validateText
                                                        }}
                                                    />
                                                </IonItem>
                                                <ErrorMessage
                                                    errors={allErrors}
                                                    name="confirm_text_all"
                                                    render={({ message }) => <div className="invalid-feedback">{message}</div>}
                                                />
                                            </IonCol>

                                        </IonRow>
                                    </IonGrid>
                                    <IonButton color="appbg" className="ion-margin-top mt-4 float-right  mb-3" type="submit">
                                        Submit
                                    </IonButton>
                                </form>
                            </IonCardContent>
                        </IonCard>
                    }
                </IonCol>
            </IonRow>
        
            <IonRow>
                <IonCol size='12' sizeMd="6">
                    { repProfile && Object.keys(repProfile).length > 0 && +(repProfile.is_primary) !== 1 &&
                        <IonCard className="card-center mt-4 mb-3">
                            <IonCardHeader color="titlebg">
                                <IonCardTitle className="card-custom-title">Sub account deletion:</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <div className="p-2">
                                    <p>Are you sure you want to delete your account? Please read how your account deletion will affect.</p>
                                    <p className="fw-bold mt-2">Account</p>
                                    <p>This is a representative sub account. Deleting this account will remove all person data from our database. 
                                        If you have submitted quotes to RFQ requests, those quotes will be marked as removed.  If you have submitted 
                                        requests for quotations, the requests will be withdrawn and deleted. The email used for this account will 
                                        become permanently retained and the same email cannot be used to register a new account or sub account. 
                                        You should check with your administrator before deleting.</p>
                                    <p className="fw-bold mt-2">Subscription</p>
                                    <p>Deleting your account will unsubscribe this representative account from all mailing lists.</p>

                                    <p className="fw-bold mt-2">Confirm Account Deletion</p>
                                    <p>Enter <span className='fw-bold'>PERMANENTLY DELETE</span> to confirm</p>
                                </div>
                                <form onSubmit={handleSingleSubmit(onSingleSubmit)} autoComplete="off">
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="12" sizeXl="6">
                                                <IonItem className="ion-no-padding">
                                                    <IonLabel position="stacked">Confirm Text  <IonText color="danger">*</IonText></IonLabel>
                                                    <Controller 
                                                        name="confirm_text_single"
                                                        control={singleControl}
                                                        render={({ field: {onChange, onBlur, value} }) => {
                                                            return <IonInput type="text"
                                                                onKeyUp={(e: any) => {
                                                                    var str = e.target.value;
                                                                    if( str.split(/\s+/).length > 10 ){
                                                                        e.target.value = str.split(/\s+/).slice(0, 10).join(" ");
                                                                    }
                                                                }} 
                                                                onIonChange={(e: any) => onChange(e.target.value)}
                                                                onBlur={onBlur}
                                                                value={value}
                                                            />
                                                        }}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: "Confirm Text is required"
                                                            },
                                                            validate: validateText
                                                        }}
                                                    />
                                                </IonItem>
                                                <ErrorMessage
                                                    errors={singleErrors}
                                                    name="confirm_text_single"
                                                    render={({ message }) => <div className="invalid-feedback">{message}</div>}
                                                />
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                    <IonButton color="appbg" className="ion-margin-top mt-4 float-right  mb-3" type="submit">
                                        Submit
                                    </IonButton>
                                </form>
                            </IonCardContent>
                        </IonCard>
                    }
                </IonCol>
            </IonRow>
        
        </IonContent> 
      
    </IonPage>
  );
};

export default RepProfileDelete;

import { 
    IonCard, 
    IonCardHeader, 
    IonCardContent,
    IonItem, 
    IonLabel,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonCardTitle,
    IonText,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonCheckbox,
    IonModal
} from '@ionic/react';
import React, { useState, useCallback, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { useDispatch, useSelector } from 'react-redux';
import * as uiActions from '../../../../store/reducers/ui';
import * as qqActions from '../../../../store/reducers/dashboard/qq';
import '../LocalQuotes.scss';
import CoreService from '../../../../shared/services/CoreService';
import QuotationStepInd from './QuotationStepInd';
import Modal from '../../../../components/Modal/Modal';
import HtmlText from '../../../../components/Common/HtmlText';
import { lfConfig } from '../../../../../Constants';

type FormInputs = {
    quotation_provideby: string;
    upload_notes: string;
    supplier_shipping_notes: string;
    qq_terms: boolean;
}

const AssignRep: React.FC = () => {
    let listReps: any = [];
    const dispatch = useDispatch();
    const { WPPAGES } = lfConfig;
    const authUser = useSelector( (state:any) => state.auth.data.user);
    const qq = useSelector( (state:any) => state.qq.localQuote);
    const quote = useSelector( (state:any) => state.qq.quotation);
    const [addQQ, setAddQQ] = useState({ status: false, memID: '', ID: '' });
    const [ reps, setReps ] = useState([]);
    const [showModal, setShowModal] = useState({status: false, title: ''});

    async function closeModal() {
        await setShowModal({status: false, title: ''});
    }

    let initialValues = {
        quotation_provideby: (quote && Object.keys(quote).length > 0 && quote.quotation_provided_by  !== '0') ? quote.quotation_provided_by : "",
        upload_notes: (quote && Object.keys(quote).length > 0 && quote.s_upload_notes) ? quote.s_upload_notes : "",
        supplier_shipping_notes: (quote && Object.keys(quote).length > 0 && quote.s_shipping_notes ) ? quote.s_shipping_notes  : "",
    };
    const { control, handleSubmit, getValues, formState: { errors } } = useForm<FormInputs>({
        defaultValues: { ...initialValues },
        mode: "onChange"
    });

    // For Reps default to load
    const onProfileCb = useCallback((res: any) => {
        if(res.status === 'SUCCESS'){
            setReps([]);
            setReps(res.reps);
        }
        dispatch(uiActions.setShowLoading({ loading: false }));
    }, [dispatch, setReps]);
    useEffect(() => {
        if( quote ){
            dispatch(uiActions.setShowLoading({ loading: true }));
            CoreService.onPostFn('get_member', {'action': 'get_reps', memID: quote.mem_id }, onProfileCb);
        }
    }, [dispatch, onProfileCb, quote]);

    const onCallbackFn = useCallback((res: any) => { // console.log(res);
        if(res.status === 'SUCCESS'){
            if( res.redirect === true ){
                setAddQQ({ status: true, memID: res.data.mem_id, ID: res.data.id  });
            }
            dispatch(qqActions.setSQ({ data: res.data }));
        }
        dispatch(uiActions.setShowLoading({ loading: false }));
        dispatch(uiActions.setShowToast({ isShow: true, status: res.status, message: res.message }));
    }, [dispatch]);
    
    const onSubmit = (data: any) => {
        dispatch(uiActions.setShowLoading({ loading: true }));
        const fd = {
            action: 'quotation_update_rep',
            memID: authUser.ID, // Seller QQ Mem ID
            repID: authUser.repID,
            formID: quote.id, // Seller QQ ID
            bqMemID: qq.mem_id, // Buyer QQ Mem ID
            bqID: qq.id, // Buyer QQ ID
            ...data
        };
        CoreService.onPostFn('qq_update', fd, onCallbackFn);
    }

    const onSave = () => {
        const data = getValues();
        dispatch(uiActions.setShowLoading({ loading: true }));
        const fd = {
            action: 'quotation_update_rep',
            memID: authUser.ID, // Seller QQ Mem ID
            repID: authUser.repID,
            formID: quote.id, // Seller QQ ID
            bqMemID: qq.mem_id, // Buyer QQ Mem ID
            bqID: qq.id, // Buyer QQ ID
            isDraft: true,
            ...data
        };
        CoreService.onPostFn('qq_update', fd, onCallbackFn);
    }

    if( quote && Object.keys(quote).length > 0 && reps && reps.length > 0 ){
        listReps = reps.map((rep: any) =>
            <IonSelectOption value={rep.rep_id} key={rep.rep_id}>{ `${rep.firstname} ${rep.lastname}`}</IonSelectOption>
        );
    }
    if( addQQ.status  ){
      return <Navigate to={`/layout/seller-request-center`} />;
    }
    return (<>
        { quote && Object.keys(quote).length > 0 &&
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <QuotationStepInd />
        <IonCard className="card-center mt-2 mb-4">
            <IonCardHeader color="titlebg">
                <IonCardTitle className="card-custom-title">Assign Representative Profile</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
            
                <IonGrid>
                    <IonRow>
                        <IonCol sizeMd="6" sizeLg="6" sizeXl="6" sizeXs="12">
                        <IonItem className="ion-no-padding">
                                <IonLabel position="stacked">Quotation Provided By <IonText color="danger">*</IonText></IonLabel>
                                { quote && Object.keys(quote).length > 0 && listReps && 
                                    <Controller 
                                        name="quotation_provideby"
                                        control={control}
                                        render={({ field: {onChange, onBlur, value} }) => {
                                            return <IonSelect
                                                placeholder="Select Rep Profile"
                                                onIonChange={(selected: any) =>{
                                                    onChange(selected.detail.value);
                                                }}
                                                onBlur={onBlur}
                                                value={value}
                                            >{listReps}</IonSelect>
                                        }}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Quotation Provided By is required"
                                            }
                                        }}
                                    />
                                } 
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="quotation_provideby"
                                render={({ message }) => <div className="invalid-feedback">{message}</div>}
                            />

                            <IonItem className="ion-no-padding">
                                <IonLabel position="stacked">Supplier Upload Notes</IonLabel>
                                <Controller 
                                    name="upload_notes"
                                    control={control}
                                    render={({ field: {onChange, onBlur, value} }) => {
                                        return <IonTextarea rows={5} cols={20}
                                            onKeyUp={(e: any) => {
                                                var str = e.target.value;
                                                if( str.split(/\s+/).length > 100 ){
                                                    e.target.value = str.split(/\s+/).slice(0, 100).join(" ");
                                                }
                                            }}
                                            onIonChange={(e: any) => onChange(e.target.value)}
                                            onBlur={onBlur}
                                            value={value}
                                        />
                                    }}
                                    rules={{
                                        pattern: {
                                            value: /^\W*(\w+(\W+|$)){1,100}$/i,
                                            message: "Supplier Upload Notes shoud be lessthan 100 words"
                                        }
                                    }}
                                />
                            </IonItem>
                            <IonText color="medium" className="fs-12">Maximum of 100 Words</IonText>
                            <ErrorMessage
                                errors={errors}
                                name="upload_notes"
                                render={({ message }) => <div className="invalid-feedback">{message}</div>}
                            />
                        </IonCol>
                    
                        <IonCol sizeMd="6" sizeLg="6" sizeXl="6" sizeXs="12">
                            { qq.p_desc && <div><span className="fw-bold">Buyer Shipping Instructions : </span><HtmlText htmlText={qq.shipping_ins} /></div>}
                            <IonItem className="ion-no-padding">
                                <IonLabel position="stacked">Supplier Shipping Notes</IonLabel>
                                <Controller 
                                    name="supplier_shipping_notes"
                                    control={control}
                                    render={({ field: {onChange, onBlur, value} }) => {
                                        return <IonTextarea rows={5} cols={20}
                                            onKeyUp={(e: any) => {
                                                var str = e.target.value;
                                                if( str.split(/\s+/).length > 100 ){
                                                    e.target.value = str.split(/\s+/).slice(0, 100).join(" ");
                                                }
                                            }}
                                            onIonChange={(e: any) => onChange(e.target.value)}
                                            onBlur={onBlur}
                                            value={value}
                                        />
                                    }}
                                    rules={{
                                        pattern: {
                                            value: /^\W*(\w+(\W+|$)){1,100}$/i,
                                            message: "Shipping Notes shoud be lessthan 100 words"
                                        }
                                    }}
                                />
                            </IonItem>
                            <IonText color="medium" className="fs-12">Maximum of 100 Words</IonText>
                            <ErrorMessage
                                errors={errors}
                                name="supplier_shipping_notes"
                                render={({ message }) => <div className="invalid-feedback">{message}</div>}
                            />
                        </IonCol>
                    </IonRow>
                    { (quote && +(quote.is_active) === 0) && <IonRow className="ion-justify-content-start">    
                        <IonCol size="1" sizeMd="0.5">
                            <div className="mt-2">
                                <Controller 
                                    name="qq_terms"
                                    control={control}
                                    render={({ field: {onChange, onBlur, value} }) => {
                                        return <IonCheckbox slot="start"
                                            onIonChange={(e: any) =>{
                                                onChange(e.detail.checked);
                                            }}
                                            onBlur={onBlur}
                                            checked={value}
                                        />
                                    }}
                                    rules={{ 
                                        required: {
                                            value: true,
                                            message: "Agree Terms & Conditions is required"
                                        }
                                    }}
                                />
                            </div>
                        </IonCol>
                        <IonCol>
                            <p className="mt-2 ml-2 cursor fs-16" onClick={() => setShowModal({status: true, title: WPPAGES.LQ_TC})}>
                                <IonText className="fs-13" color="primary">I Agree to the terms and conditions <i className="fa fa-question-circle-o" aria-hidden="true"></i></IonText>
                            </p>    
                            <ErrorMessage
                                errors={errors}
                                name="qq_terms"
                                render={({ message }) => <div className="invalid-feedback">{message}</div>}
                            />
                        </IonCol>
                    </IonRow> }
                </IonGrid>
                
                <IonButton slot="end" color="appbg" className="ion-margin-top mt-4 float-right mb-3" type="submit">
                    Submit
                </IonButton>
                { (quote && +(quote.is_active) === 0) && <IonButton slot="start" color="primary" className="ion-margin-top mt-4 mr-4 float-right mb-3" type="button" onClick={onSave}>
                    Save as Draft
                </IonButton> }
                
            </IonCardContent>
        </IonCard>
        </form>}
        <IonModal backdropDismiss={false} isOpen={showModal.status} className='my-custom-class'>
            <Modal title = {showModal.title} closeAction={closeModal} />
        </IonModal>
    </>);
};
 
export default AssignRep;
  
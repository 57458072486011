import { IonPage, IonContent, IonToolbar, IonSegment, IonSegmentButton, IonLabel, IonGrid, IonRow, IonCol,
  IonInfiniteScroll, 
  IonInfiniteScrollContent,
  IonSpinner,
  IonText,
  IonRouterLink
} from '@ionic/react';
import React, { useState, useCallback, useEffect, useLayoutEffect } from 'react';
import './Home.scss';
import { useDispatch, useSelector } from 'react-redux';
import { isPlatform } from '@ionic/react';
import * as uiActions from '../../store/reducers/ui';
import { lfConfig } from '../../../Constants';
import HomeItems from './HomeItems';
import PartnerAds from '../../components/Common/PartnerAds';
import axios from 'axios';

const Home: React.FC = () => { 
  const dispatch = useDispatch();
  const { ADS } = lfConfig;
  const [filter, setFilter] = useState<any>('all');
  const [page, setPage] = useState<number>(1);
  const location = useSelector( (state:any) => state.auth.location);
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState(0);

  const [homeResults, setHomeResults] = useState<string[]>([]);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);

  useLayoutEffect(() => { 
    function updateSize() {
      setWidth(window.innerWidth); 
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  
  useEffect(() => {
    // if(width > 0){
      // setPage(1);
      setHomeResults([]);
      fetchData(true);
    // }
  }, [filter]);
  const handleFilter = useCallback((e: any) => { // console.log('Frome Filter');
    if (e !== undefined && e !== null) {
      const fltr = e.detail.value;
      setPage(1);
      setFilter(fltr);
    }
  }, []);

  async function fetchData(reset?: boolean, $event?:any) {
    if(page == 1){ setLoading(true); }
    const homeData: any[] = reset ? [] : homeResults;
    const data = {
      action: 'home',
      filter,
      geolocation: location,
      page,
      pagewidth: width? width: window.innerWidth
    };
    axios.post(`v2/search`, data )
      .then( (res: any) => { // console.log(res);
        if (res.status === 200 && res.data.data && res.data.data.length > 0) { // console.log('True section');
          setHomeResults([...homeData, ...res.data.data]);
          setPage(prevPage => prevPage+1);
          setDisableInfiniteScroll(res.data.data.length < 2);
        } else { // console.log('False section');
            // dispatch(uiActions.setShowToast({ isShow: true, status: res.status, message: res.message })); 
            setDisableInfiniteScroll(true);
        }
        if($event){
          // ($event.target as HTMLIonInfiniteScrollElement).complete();
          ($event.target).complete();
        }
        if(page == 1){ setLoading(false); }
      })
      .catch( (error: any) => {  console.log(error);
        dispatch(uiActions.setShowToast({ isShow: true, status: 'ERROR', message: error }));
        console.error(error);
        if(page == 1){ setLoading(false); }
      });
  }

  async function searchNext($event: CustomEvent<void>) {
      await fetchData(false, $event);
  }
  
  return (
    <IonPage className="ion-page homepage">
      <IonContent>
        <IonRow className="ion-justify-content-between m-2 ion-hide-md-up md">
          <IonCol size="6" className="md">
          { location.city && <div className="location-wrap ion-hide-md-up">
              <IonText className="location-text md">Shop Local-First</IonText><br />
              <i className="fa fa-map-marker fa-lg mx-2" aria-hidden="true"></i>
              <IonText className="location-subtext md">in {location.city}</IonText>
            </div>}
          </IonCol>
          <IonCol size="6" className="md">
            <div className="ion-float-right pt-3">
              <IonRouterLink slot="end" color="yellow" href={`/signup`} className="btn-round ion-color ion-color-appbg md ion-activatable"> Get Local Quotes</IonRouterLink>
            </div>
          </IonCol>
        </IonRow>
        <IonToolbar className="p-3" color="white">
          <IonSegment mode="ios" className="filter-toolbar" scrollable value={filter} onIonChange={(e) => handleFilter(e)}>
            <IonSegmentButton className="tb-item" value="all" >
              <IonLabel className="ion-text-capitalize fs-14">All</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton className="tb-item" value="localdeals">
              <IonLabel className="ion-text-capitalize fs-14">Local Deals</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton className="tb-item" value="pressreleases">
              <IonLabel className="ion-text-capitalize fs-14">{(isPlatform('desktop'))? `Business News`:`Business News`}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>

        <IonGrid className="pb-4 home-container ion-padding" >
          <IonRow>
            <IonCol sizeXs="12" sizeMd="9" sizeXl="9">
              {loading && <div className="d-flex justify-content-center my-3"><IonSpinner name="dots" /></div> }
              { !loading && filter === 'localdeals' && homeResults.length === 0 && <div className="d-flex justify-content-center my-3 red">No Localdeals found.</div> }
              { !loading && filter === 'pressreleases' && homeResults.length === 0 && <div className="d-flex justify-content-center my-3 red">No Business News found.</div> }
              <HomeItems dwidth={width} homeResults={homeResults} />
              <IonRow>
                <IonCol>
                  <IonInfiniteScroll 
                    threshold="100px" 
                    disabled={disableInfiniteScroll}
                    onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
                      <IonInfiniteScrollContent
                        loadingSpinner="dots"
                        loadingText="Loading more data..."
                      ></IonInfiniteScrollContent>
                  </IonInfiniteScroll>
                </IonCol>
              </IonRow>    

            </IonCol>
            { (isPlatform('desktop')) && 
              <IonCol sizeXs="12" sizeMd="3" sizeXl="3" className="side-media-container">
                <PartnerAds limit={ADS.HOME} />
              </IonCol>
            }
          </IonRow>
        </IonGrid>
        
      </IonContent>
    </IonPage>
  );
};

export default Home;

import {
    IonFab,
    IonFabButton,
    IonIcon,
    IonFabList,
    IonRouterLink,
    useIonAlert
  } from '@ionic/react';
import React, { useState, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { 
    ellipsisHorizontalOutline,
    trashOutline,
    pauseOutline,
    playOutline
  } from 'ionicons/icons';
import './RepProfile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { lfConfig } from '../../../../Constants';
import * as uiActions from '../../../store/reducers/ui';
import * as authActions from '../../../store/reducers/auth';
import CoreService from '../../../shared/services/CoreService';
import * as repActions from '../../../store/reducers/dashboard/rep';

const RepActions: React.FC = () => {
    const dispatch = useDispatch(); 
    const repProfile = useSelector( (state:any) => state.rep.repTempProfile); // console.log(repProfile);
    const [delRep, setDelRep] = useState({ status: false, memID: '', repID: ''  });
    const { basename } = lfConfig;
    const [presentAlert] = useIonAlert();

    const onCallbackFn = useCallback((res: any) => {
        if(res.status === 'SUCCESS'){
            dispatch(repActions.setRepTempProfile({ data: res.data.rep }));
            if( res.redirect === true ){
                dispatch(authActions.setMenu({ menu: res.data.menu }));
                setDelRep({ status: true, memID: res.data.rep.mem_id, repID: res.data.rep.id  });
            }
        }
        dispatch(uiActions.setShowLoading({ loading: false }));
        dispatch(uiActions.setShowToast({ isShow: true, status: res.status, message: res.message }));
    }, [dispatch]);
    
    const onSuspend = () => {
        dispatch(uiActions.setShowLoading({ loading: true }));
        const fd = {
            action: 'profile_suspend',
            memID: repProfile.mem_id,
            repID: repProfile.id,
        };
        CoreService.onPostFn('member_update', fd, onCallbackFn);
    }

    const onActivate = () => {
        dispatch(uiActions.setShowLoading({ loading: true }));
        const fd = {
            action: 'profile_activate',
            memID: repProfile.mem_id,
            repID: repProfile.id,
        };
        CoreService.onPostFn('member_update', fd, onCallbackFn);
    }

    const onDelete = () => { 
        dispatch(uiActions.setShowLoading({ loading: true }));
        const fd = {
            action: 'profile_delete',
            memID: repProfile.mem_id,
            repID: repProfile.id,
        };
        CoreService.onPostFn('member_update', fd, onCallbackFn);
    }
    /*const onProfileView = () => { // mem_id: number, rep_id: number
        console.log('Meow');
        return <Navigate to={`${basename}/profile/${repProfile.mem_id}/${repProfile.id}`} />;
    }*/

    if( delRep.status  ){
        // return <Navigate to={`/layout/rep-profile/${delRep.memID}/${delRep.repID}`} />;
        return <Navigate to={`/layout/dashboard`} />;
    }
    
    return (<>
        { repProfile && Object.keys(repProfile).length > 0 &&
        <IonFab horizontal="end" vertical="bottom" slot="fixed">
            <IonFabButton color="appbg">
                <IonIcon icon={ellipsisHorizontalOutline} />
            </IonFabButton>
            <IonFabList side="start">
                
                { +(repProfile.is_primary) === 0 && repProfile.rep_account === 'sub' && <>
                    <IonFabButton color="appbg" title="To Delete" onClick={()=> presentAlert({
                        header: `Delete Account`,
                        subHeader: 'Are you sure you want to delete this profile?',
                        cssClass: 'custom-alert',
                        buttons: [
                          {
                            text: 'No',
                            cssClass: 'alert-button-cancel',
                            handler: () => {
                              console.log('This is cancel');
                            }
                          },
                          {
                            text: 'Yes',
                            cssClass: 'alert-button-confirm',
                            handler: () => {
                                onDelete();
                            }
                          },
                        ],
                      })}>
                        <IonIcon icon={trashOutline}></IonIcon>
                    </IonFabButton>
                    { [0,1].includes(+(repProfile.is_active)) &&
                        <IonFabButton color="appbg" title="To Suspend" onClick={() => onSuspend()}>
                            <IonIcon icon={pauseOutline}></IonIcon>
                        </IonFabButton> 
                    }
                    { +(repProfile.is_active) === 2 &&
                        <IonFabButton color="appbg" title="To Activate" onClick={() => onActivate()}>
                            <IonIcon icon={playOutline}></IonIcon>
                        </IonFabButton>
                    }
                </>}
                <IonFabButton color="appbg" title="To View">
                    <IonRouterLink color="blackbg" href={`/profile/${repProfile.mem_id}/${repProfile.id}`}>
                        {/* <IonIcon icon={eyeOutline} size="small"></IonIcon> */}
                        <i className="fa fa-eye fa-lg" aria-hidden="true"></i>
                    </IonRouterLink>
                </IonFabButton>
            </IonFabList>
        </IonFab>
        }
        
    </>);
};
  
export default RepActions;
  

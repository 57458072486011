import React, { Component } from 'react';
import { IonBreadcrumb, IonBreadcrumbs } from '@ionic/react';
interface PropsInterface{
  step1?: {
    label: string,
    link: string
  },
  step2?: {
    label: string,
    link: string
  },
  step3?: {
    label: string,
    link: string
  }
}
const Breadcrumbs: React.FC<PropsInterface> = ({step1, step2, step3}) => { 
    return (
      <>
        <IonBreadcrumbs className="pl-2">
          <IonBreadcrumb routerLink={`/`}>Home</IonBreadcrumb>
          { step1 && Object.keys(step1).length > 0 && step1.constructor === Object && step1.link ?
            <IonBreadcrumb routerLink={step1.link? step1.link: ``}>{step1.label}</IonBreadcrumb>:
            <IonBreadcrumb >{step1.label}</IonBreadcrumb>
          }
          { step2 && <>
            { Object.keys(step2).length > 0 && step2.constructor === Object && step2.link?
            <IonBreadcrumb routerLink={step2.link? step2.link: ``}>{step2.label}</IonBreadcrumb>:
            <IonBreadcrumb >{step2.label}</IonBreadcrumb>}
          </>}
          { step3 && <>
            { Object.keys(step3).length > 0 && step3.constructor === Object && step3.link?
            <IonBreadcrumb routerLink={step3.link? step3.link: ``}>{step3.label}</IonBreadcrumb>:
            <IonBreadcrumb >{step3.label}</IonBreadcrumb>}
          </>}
        </IonBreadcrumbs>
      </>
    );
  };

export default Breadcrumbs;
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonRouterLink, IonRow, IonCol, IonItem, IonGrid, IonThumbnail, IonLabel, IonText, IonFabButton, IonIcon, IonFab, IonModal } from '@ionic/react';
import { chatboxEllipsesOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import '../LocalQuotes.scss';
import { lfConfig } from '../../../../../Constants';
import CommonService from '../../../../shared/services/CommonService';
import RFQStatus from '../../../../components/Common/RFQStatus';
import Chat from '../../../../components/Modal/Chat/Chat';
import { Browser } from '@capacitor/browser';

let initialValues = {
    isOpen: false,
    title: '',
    qq_type: '',
    bid: 0,
    brep_id: 0,
    bmem_id: 0,
    sid: 0,
    srep_id: 0,
    smem_id: 0
};

interface Props {
    qt: any,
    setShowActionSheet?: Function,
    setShowPopover?: Function,
    setShowDeleteModal?: Function
}

const QuotationContent: React.FC<Props> = (props) => { // {qt, setShowActionSheet, setShowPopover, setShowDeleteModal}
    const { basename } = lfConfig;
    const {qt, setShowActionSheet, setShowPopover } = props;
    const authUser = useSelector( (state:any) => state.auth.data.user);
    let qq:any = {};
    let repImage = '';
    const { apiBaseURL } = lfConfig;
    const [showChatModal, setShowChatModal] = useState(initialValues);

    const openProfileLink = async (mem_id: number, rep_id: number) => {
        await Browser.open({ url: `/profile/${mem_id}/${rep_id}` });
    };

    const handleChatFn = (bid: number, brep_id: number, bmem_id: number, sid: number, srep_id: number, smem_id: number) => {
        setShowChatModal({ 
            ...showChatModal, 
            isOpen: true,
            title: 'Message Center',
            qq_type: 'seller',
            bid, // Buyer QQ ID
            brep_id, // Buyer Rep ID
            bmem_id, // Buyer Mem ID
            sid, // Seller QQ ID
            srep_id, // Seller Rep ID
            smem_id, // Seller Mem ID
        });
    }

    if( qt.localquote && Object.keys(qt.bq_id).length > 0  ){
        qq = qt.localquote;
        repImage = (Object.keys(qq).length > 0 && qq.profile_image) ? `${apiBaseURL}uploads/member/${qq.mem_id}/${qq.rep_id}/${qq.profile_image}` : `${basename}/assets/img/avatar.svg`;
    }
    return (<IonCard className="card-center mt-3 mb-1">
        <IonCardHeader color="titlebg">
            <IonCardTitle className="card-title">
                <IonRouterLink color="appbg" href={`/layout/view-quotation/${qt.id}/${qt.mem_id}/${qq.id}/${qq.mem_id}/seller`}>
                   {qt.s_title}
                </IonRouterLink>
                { setShowActionSheet && <IonRouterLink color="appbg" onClick={() => setShowActionSheet({status: true, qt: qt})} className="float-right router-link-anchor-vertical">
                    <i className="fa fa-ellipsis-v fa-lg iconbg cursor" aria-hidden="true"></i>
                </IonRouterLink> }
            </IonCardTitle>
            <p className="m-0 pt-1 gray">{CommonService.dateFormat(qt.quotation_date)}</p>
        </IonCardHeader>
        <IonCardContent>
            
            <IonGrid>
                <IonItem lines="none">
                    { qt && Object.keys(qt).length > 0 && qq && Object.keys(qq).length > 0  && <IonGrid>   
                        <IonRow>
                            <IonCol sizeMd="6">
                                <IonItem lines="none" >
                                    <IonThumbnail slot="start" color="appbg">
                                        <img src={repImage} alt="Rep Profile" onError={() => CommonService.onImgErr('profile')} />
                                    </IonThumbnail>
                                    
                                    <IonLabel>
                                        <h2 className='ion-text-wrap'>{qq.p_title}</h2>
                                        {/* { qq.firstname && setShowPopover && <h3 className="cursor anchor-link" onClick={() => setShowPopover({status: true, qq: qq})}>{`${qq.firstname} ${qq.lastname}`}</h3> } */}
                                        <h3 className="cursor anchor-link" onClick={()=> openProfileLink(qq.mem_id, qq.rep_id) }>
                                            {`${qq.firstname} ${qq.lastname}`}
                                        </h3>
                                        { +(qq.is_deleted) === 0 && <p>
                                            <IonRouterLink href={`/layout/view-localquote/${qq.id}/${qq.mem_id}/seller`}>
                                                View Original RFQ
                                            </IonRouterLink>
                                        </p>}
                                    </IonLabel>
                                    <IonFab vertical="bottom" horizontal="end" >
                                        <IonFabButton color="appbg" size="small" onClick={()=>handleChatFn(qq.id, qq.rep_id, qq.mem_id, qt.id, authUser.repID, authUser.ID)}> 
                                            <IonIcon icon={chatboxEllipsesOutline} size="small" />
                                        </IonFabButton>
                                    </IonFab>
                                </IonItem>
                            </IonCol>
                            <IonCol sizeMd="6">
                                <IonItem lines="none" >
                                    <IonLabel>
                                        <p><span className="fw-bold">Date of Request : </span>{CommonService.dateFormat(qq.quotation_date)}</p>
                                        <p><span className="fw-bold">Response Deadline : </span>{CommonService.dateFormat(qq.quotation_req_date)}</p>
                                        <p><span className="fw-bold">Status: </span> 
                                            { +(qq.is_deleted) === 1 && <IonText color="danger">Deleted</IonText> }
                                            { +(qq.is_deleted) === 0 && <RFQStatus status={+(qq.is_active)} plainText={false} frontend={false}/> }
                                        </p>
                                        { +(qq.is_active) === 4  && <p className='ion-text-wrap'>
                                            <IonText color="danger">Congratulations you are being Awarded with this sale! Please provide the best method to send you the purchase documents through the message system.</IonText>
                                        </p>}
                                        
                                    </IonLabel>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </IonGrid>}
                </IonItem>
            </IonGrid>

        </IonCardContent>
        <IonCardHeader color="titlebg">
            <IonRow>
                <IonCol>
                    Status: <RFQStatus status={+(qt.is_active)} plainText={false} frontend={false}/>
                </IonCol>
            </IonRow>
        </IonCardHeader>
        <IonModal backdropDismiss={false} isOpen={showChatModal.isOpen} className='view-modal-wrap'>
            { showChatModal.isOpen === true &&  <Chat
                showChatModal={showChatModal}
                setShowChatModal={setShowChatModal} 
           /> }
        </IonModal>
    </IonCard>);
};

export default QuotationContent;

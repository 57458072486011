import { IonContent, IonPage, IonList, IonAvatar, IonItem, IonLabel, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItemSliding, IonItemOptions, IonThumbnail, IonRouterLink, IonAlert, IonItemOption} from '@ionic/react';
import React, {useCallback, useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { isPlatform } from '@ionic/react';
import './Ads.scss';
import { useDispatch, useSelector } from 'react-redux';
import * as uiActions from '../../../store/reducers/ui';
import * as adActions from '../../../store/reducers/dashboard/ads';
import { lfConfig } from '../../../../Constants';
import CoreService from '../../../shared/services/CoreService';
import CommonService from '../../../shared/services/CommonService';
import ListSkeleton from '../../../components/Skeleton/ListSkeleton';
import Status from '../../../components/Common/Status';
import { nanoid } from 'nanoid';
// import LocalDealsUnpaid from './LocalDealsUnpaid';
import NoData from '../../../components/Common/NoData';
import Breadcrumbs from '../../../components/Header/Breadcrumbs';
import LocationText from '../../../components/Common/LocationText';

const AdsList: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const skeleton = useSelector( (state:any) => state.ui.skeleton);
  const authUser = useSelector( (state:any) => state.auth.data.user);
  const ads = useSelector( (state:any) => state.ads.ads);
  const { apiBaseURL, basename } = lfConfig;
  const [showAlert, setShowAlert] = useState({status: false, id: '', mem_id: '', rep_id: '', recurring: '' });
  const [showSuspendAlert, setShowSuspendAlert] = useState({status: false, id: '', mem_id: '', rep_id: '', is_active: 0, recurring: '' });
  const [showUnSuspendAlert, setShowUnSuspendAlert] = useState({status: false, id: '', mem_id: '', rep_id: '', is_active: 0, recurring: '' });
  const [showCancelAlert, setShowCancelAlert] = useState({status: false, id: '', mem_id: '', rep_id: '' });

  const onCallbackFn = useCallback((res: any) => {
    if(res.status === 'SUCCESS'){
      dispatch(adActions.setAds({ data: res.data }));
    }else{
      dispatch(uiActions.setShowToast({ isShow: true, status: res.status, message: res.message }));
    }
    dispatch(uiActions.setShowSkeleton({ skeleton: false }));
  }, [dispatch]);

  useEffect(() => { 
    if( authUser && authUser.ID ){
      dispatch(uiActions.setShowSkeleton({ skeleton: true }));
      const data = {
        action: 'get_ads',
        memID: authUser.ID,
        repID: authUser.repID,
        unpaid: true
      };
      CoreService.onPostFn('ad_update', data, onCallbackFn);
    }
  }, [dispatch, onCallbackFn, authUser]); 

  const onDeleteCb = useCallback((res: any) => {
    if(res.status === 'SUCCESS'){
      /* dispatch(authActions.setDealsCountUpdate({ total: res.data.length }));
      const cusData = {
        deals: res.data,
        unpaid: res.unpaid
      }*/
      dispatch(adActions.setAds({ data: res.data }));
    }
    dispatch(uiActions.setShowSkeleton({ skeleton: false }));
    dispatch(uiActions.setShowToast({ isShow: true, status: res.status, message: res.message }));
  }, [dispatch]);

  const onDeleteFn = async (id: number, mem_id: number, rep_id: number) => {
    dispatch(uiActions.setShowSkeleton({ skeleton: true }));
    const fd = {
        action: 'ad_delete',
        memID: mem_id,
        repID: rep_id,
        formID: id
    };
    CoreService.onPostFn('ad_update', fd, onDeleteCb);
  }

  // Supend /UnSuspend the Ad
  const onSuspendUnSuspendFn = async (id: number, mem_id: number, rep_id: number, status: number) => {
    dispatch(uiActions.setShowSkeleton({ skeleton: true }));
    const fd = {
        action: status === 3? 'ad_unsuspend':'ad_suspend',
        memID: mem_id,
        repID: rep_id,
        formID: id
    };
    CoreService.onPostFn('ad_update', fd, onDeleteCb);
  }

  // Cancel the Ad Subscription
  const onCancelFn = async (id: number, mem_id: number, rep_id: number) => {
    dispatch(uiActions.setShowSkeleton({ skeleton: true }));
    const fd = {
        action: 'ad_cancel',
        memID: mem_id,
        repID: rep_id,
        formID: id
    };
    CoreService.onPostFn('ad_update', fd, onDeleteCb);
  }

  const slideEdit = (item: any) => {
    navigate(`/layout/ads/create-ad/${item.id}/${item.mem_id}/1`);
  }
  let noNameCount = 0;
  return (
    <IonPage className="sponsor-page">
      <Breadcrumbs step1={{label:'Sponsor Ads', link: ''}} />  
        <IonContent>
          <IonCard className="card-center my-4">
            <IonCardHeader color="titlebg">
                <IonCardTitle className="card-custom-title">Sponsor Ads
                  <IonRouterLink color="appbg" href={`/layout/ads/buy-ads`} className="float-right router-link-anchor" title="Buy an Ad">
                    <i className="fa fa-plus iconbg cursor" aria-hidden="true"></i>
                  </IonRouterLink>
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              { (ads && !skeleton.showSkeleton)  ? ( 
              <IonList className="buscat-section-content">
                {/* { unpaid && unpaid.length > 0  && <LocalDealsUnpaid />} */}
                { ads.length > 0  &&  ads.map((item: any, index: number)=> {
                  // console.log(item.id+"==" + (!item.sdate || (item.sdate && new Date(Date.parse(item.sdate.replace(/[-]/g,'/'))).valueOf()/1000 > Date.now())));
                  const ddImage = ( item && Object.keys(item).length > 0 && item.image) ? `${apiBaseURL}uploads/member/${item.mem_id}/${item.rep_id}/${item.image}` : `${basename}/assets/img/placeholder.png`;
                  if(!item.name){
                    noNameCount++;
                  }
                  return (<div key={nanoid()}>
                    { (!isPlatform('desktop')) &&   
                      <IonItemSliding>
                        
                        <IonItemOptions side="start">
                          { [1,2].includes(+(item.is_active)) && <IonItemOption color="appbg" onClick={() => setShowSuspendAlert({status: true, id: item.id, mem_id: item.mem_id, rep_id: item.rep_id, is_active: 1, recurring: item.recurring}) } title="Suspend">Suspend</IonItemOption> }
                          { [3].includes(+(item.is_active)) && <IonItemOption color="appbg" onClick={() => setShowUnSuspendAlert({status: true, id: item.id, mem_id: item.mem_id, rep_id: item.rep_id, is_active: 3, recurring: item.recurring}) } title="UnSuspend">UnSuspend</IonItemOption> }
                          { [0,1].includes(+(item.is_active)) && item.recurring === 'yes' && item.recurring_status !== 'canceled' &&<IonItemOption color="warning" onClick={() => setShowCancelAlert({status: true, id: item.id, mem_id: item.mem_id, rep_id:item.rep_id })} title="Trash">Cancel Subscription</IonItemOption> }
                        </IonItemOptions>
                        <IonItem lines={ (ads.length === index+1)? "none": "inset" } routerLink={`/layout/ads/create-ad/${item.id}/${item.mem_id}/1`}>
                          <IonAvatar slot="start" color="appbg">
                            <img className="roundcorner" src={ddImage} alt="Partner Ad Media" />
                          </IonAvatar>
                          <IonLabel>
                            <h2>{item.title? item.title: `No Ad Title ${noNameCount}`}</h2>
                            <p><LocationText location={+(item.location)} plainText={false} /> : <b>$</b> {item.price}</p>
                            <p>
                              <Status is_active={+(item.is_active)} type="sponsor_ads" text={true}/>
                              { (item.sdate && item.edate) &&  <span>{` `+CommonService.dateReadFormat(item.sdate)} - {CommonService.dateReadFormat(item.edate)}</span> }
                            </p>
                          </IonLabel>
                        </IonItem>
                        <IonItemOptions side="end">
                          { (!item.sdate || +(item.is_active) === 0 || (item.sdate && +(item.is_active) !== 0 && (item.edate && (new Date()).getTime() < new Date(item.edate).getTime() ))) &&
                            <IonItemOption className="px-2" color="appbg" onClick={() => slideEdit(item) } title="Edit">Edit</IonItemOption>
                          }
                          <IonItemOption color="warning" onClick={() => setShowAlert({status: true, id: item.id, mem_id: item.mem_id, rep_id:item.rep_id, recurring: item.recurring })} title="Trash">Delete</IonItemOption>
                        </IonItemOptions>
                      </IonItemSliding>
                    }
                    { (isPlatform('desktop')) &&
                    <IonItem lines={ (ads.length === index+1)? "none": "inset" }>
                      <IonThumbnail slot="start" color="appbg">
                        <IonRouterLink href={`/layout/ads/create-ad/${item.id}/${item.mem_id}/1`}>
                          <img className="roundcorner" src={ddImage} alt="Partner Ad Media" />
                        </IonRouterLink>
                        <IonLabel>
                          <Status is_active={+(item.is_active)} type="sponsor_ads" text={true} />
                        </IonLabel>
                      </IonThumbnail>
                      <IonLabel>
                        <IonRouterLink color="dark" href={`/layout/ads/create-ad/${item.id}/${item.mem_id}/1`}>
                          <h2>{item.title? item.title: `No Ad Title ${noNameCount}` }</h2>
                        </IonRouterLink>
                        <p><LocationText location={+(item.location)} plainText={false} /> : <b>$</b> {item.price}</p>
                        { (item.sdate && item.edate) && 
                            <p>{` `+CommonService.dateReadFormat(item.sdate)} - {CommonService.dateReadFormat(item.edate)}</p>
                        }
                        <p>
                          { [1,2].includes(+(item.is_active)) && 
                          <IonRouterLink className="router-link-anchor pr-3" slot="end" onClick={() => setShowSuspendAlert({status: true, id: item.id, mem_id: item.mem_id, rep_id: item.rep_id, is_active: 1, recurring: item.recurring}) } title="Suspend">
                            Suspend My Sponsor Ad
                          </IonRouterLink>}
                          { [3].includes(+(item.is_active)) && 
                          <IonRouterLink className="router-link-anchor pr-3" slot="end" onClick={() => setShowUnSuspendAlert({status: true, id: item.id, mem_id: item.mem_id, rep_id: item.rep_id, is_active: 3, recurring: item.recurring}) } title="UnSuspend">
                            UnSuspend My Sponsor Ad
                          </IonRouterLink>}
                          { [0,1].includes(+(item.is_active)) && item.recurring === 'yes' && item.recurring_status !== 'canceled' &&
                          <IonRouterLink className="router-link-anchor" slot="end" onClick={() => setShowCancelAlert({status: true, id: item.id, mem_id: item.mem_id, rep_id:item.rep_id })} title="Cancel">
                            Cancel My Ad Subscription
                          </IonRouterLink>}
                        </p>
                      </IonLabel>
                    
                      { (!item.sdate || +(item.is_active) === 0 || (item.edate && (new Date()).getTime() <= new Date(item.edate).getTime() )) &&
                      <IonRouterLink className="router-link-anchor" slot="end" color="appbg" href={`/layout/ads/create-ad/${item.id}/${item.mem_id}/1`} title="Edit">
                        <i className="fa fa-pencil fa-lg iconbg cursor" aria-hidden="true"></i>
                      </IonRouterLink>
                      }
                      <IonAvatar className="anchor-white-ellipsis" slot="end" color="appbg" onClick={() => setShowAlert({status: true, id: item.id, mem_id: item.mem_id, rep_id: item.rep_id, recurring: item.recurring })} title="Trash">
                        <i className="fa fa-trash fa-lg iconbg cursor" aria-hidden="true"></i>
                      </IonAvatar>
        
                    </IonItem>
                    }
                  </div>)
                })} 
                </IonList>
                ) : ( <ListSkeleton /> )} 
                <NoData dataArr={ads} htmlText="No Ads added." />
            </IonCardContent>
          </IonCard>  
          
        </IonContent>
        <IonAlert
            isOpen={showAlert.status}
            onDidDismiss={() => setShowAlert({status: false, id: '', mem_id: '', rep_id: '', recurring: '' })}
            header={'Confirm!'}
            message={ showAlert.recurring === 'yes'? 
            'Are you sure want to delete this Ad? If you delete this ad it will be removed permanently from the Local First System and Cancel your subscription immediately.' : 
            'Are you sure want to delete this Ad? If you delete this ad it will be removed permanently from the Local First System immediately.'
          }
            buttons={[
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'primary'
              },
              {
                text: 'Okay',
                cssClass: 'appbg',
                handler: () => {
                  onDeleteFn( +(showAlert.id), +(showAlert.mem_id), +(showAlert.rep_id));
                }
              }
            ]}
          />
        <IonAlert
            isOpen={showCancelAlert.status}
            onDidDismiss={() => setShowCancelAlert({status: false, id: '', mem_id: '', rep_id: '' })}
            header={'Confirm!'}
            message={'Are you sure want to Cancel this Ad? On cancelling your ad will continue to be displayed until your subscription runs out. If you wish for the ad not to be seen then you must also suspend your ad.'}
            buttons={[
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'primary'
              },
              {
                text: 'Okay',
                cssClass: 'appbg',
                handler: () => {
                  onCancelFn( +(showCancelAlert.id), +(showCancelAlert.mem_id), +(showCancelAlert.rep_id));
                }
              }
            ]}
          />  
        <IonAlert
            isOpen={showSuspendAlert.status}
            onDidDismiss={() => setShowSuspendAlert({status: false, id: '', mem_id: '', rep_id: '', is_active: 0, recurring: '' })}
            header={'Confirm!'}
            message={showSuspendAlert.recurring === 'yes'? 
            'Are you sure want to Suspend this Ad? On suspending your ad it will no longer be shown on The Local First App. You can un-suspend your ad at any time. Suspending does not affect your ad subscription timing or renewal dates.' : 
            'Are you sure want to Suspend this Ad? On suspending your ad it will no longer be shown on The Local First App. You can un-suspend your ad at any time. '}
            buttons={[
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'primary'
              },
              {
                text: 'Okay',
                cssClass: 'appbg',
                handler: () => {
                  onSuspendUnSuspendFn( +(showSuspendAlert.id), +(showSuspendAlert.mem_id), +(showSuspendAlert.rep_id), +(showSuspendAlert.is_active));
                }
              }
            ]}
          />  
        <IonAlert
            isOpen={showUnSuspendAlert.status}
            onDidDismiss={() => setShowUnSuspendAlert({status: false, id: '', mem_id: '', rep_id: '', is_active: 0, recurring: '' })}
            header={'Confirm!'}
            message={
              showSuspendAlert.recurring === 'yes'? 
              'Are you sure want to Un-suspend this Ad? On Un-suspending your ad it will be shown on The Local First App. Un-suspending does not affect your ad subscription timing or renewal dates.' : 
              'Are you sure want to Un-suspend this Ad? On Un-suspending your ad it will be shown on The Local First App.'
            }
            buttons={[
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'primary'
              },
              {
                text: 'Okay',
                cssClass: 'appbg',
                handler: () => {
                  onSuspendUnSuspendFn( +(showUnSuspendAlert.id), +(showUnSuspendAlert.mem_id), +(showUnSuspendAlert.rep_id), +(showUnSuspendAlert.is_active));
                }
              }
            ]}
          />   
    </IonPage>
  );
};

export default AdsList;

import React, { Suspense } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { IonContent } from '@ionic/react';
// import { lfConfig } from './Constants';
import PrivateRoute from './AuthGuard';

import Home from './app/pages/Home/Home';
import NoPage from './app/pages/NoPage/NoPage'; 
import Login from './app/pages/Login/Login';
import Header from './app/components/Header/Header';
import Footer from './app/components/Footer/Footer';
//import Layout from './app/pages/Layout/Layout';
import Signup from './app/pages/Signup/Signup';
import EmailVerify from './app/pages/EmailVerify/EmailVerify';
import ForgetPassword from './app/pages/ForgetPassword/ForgetPassword';
import AboutUs from './app/pages/AboutUs/AboutUs';
import PrivacyPolicy from './app/pages/PrivacyPolicy/PrivacyPolicy';
import ContactUs from './app/pages/ContactUs/ContactUs';
import Testing from './app/pages/Testing/Testing';
import TestingVideo from './app/pages/Testing/TestingVideo';
import Profile from './app/pages/Profile/Profile';
import PreResults from './app/pages/Search/Preliminary/Results';
// import MainResults from './app/pages/Search/Main/Results';

import CompanyProfile from  './app/pages/Layout/CompanyProfile/CompanyProfile';
import RepProfile from './app/pages/Layout/RepProfile/RepProfile';
import NewRep from './app/pages/Layout/RepProfile/AddRep/NewRep';
import Dashboard from './app/pages/Layout/Dashboard/Dashboard';
import PreviewProfile from './app/pages/Layout/Dashboard/PreviewProfile/PreviewProfile';
import HomePR from './app/pages/PressReleases/PR';
import PressRelease from './app/pages/Layout/PressRelease/PressRelease';
import PressReleases from './app/pages/Layout/PressRelease/PressReleases';
import AddPressRelease from './app/pages/Layout/PressRelease/AddPressRelease/AddPressRelease';
import AddResource from './app/pages/Layout/ResourceUpload/Add/AddResource';
import Resources from './app/pages/Layout/ResourceUpload/Resources';
import Resource from './app/pages/Layout/ResourceUpload/Resource';
import HomeLD from './app/pages/LocalDeals/LocalDeal';
import LocalDeal from './app/pages/Layout/Deals/LocalDeal';
import LocalDeals from './app/pages/Layout/Deals/LocalDeals';
import AddDeal from './app/pages/Layout/Deals/AddDeal/AddDeal';
import BuyDeal from './app/pages/Layout/Deals/Premium/Buy';
import DealPayment from './app/pages/Layout/Deals/Premium/DealPayment';
import Ads from './app/pages/Layout/Ads/AdsList';
import CreateAd from './app/pages/Layout/Ads/CreateAd/Create';
import BuyAds from './app/pages/Layout/Ads/Premium/Buy';
import AdPayment from './app/pages/Layout/Ads/Premium/AdPayment';

// import QuickQuotes from './app/pages/Layout/QuickQuotes/QuickQuotes';
import ViewLQ from './app/pages/Layout/LocalQuotes/View/ViewLQ';
import ViewQuotation from './app/pages/Layout/LocalQuotes/View/ViewQuotation';
import AddLQ from './app/pages/Layout/LocalQuotes/Add/AddLQ';
import BuyerRequestCenter from './app/pages/Layout/LocalQuotes/Buyer/BuyerRequestCenter';
import SellerRequestCenter from './app/pages/Layout/LocalQuotes/Seller/SellerRequestCenter';
import Quotation from './app/pages/Layout/LocalQuotes/Quotation/Quotation';
import MyQuotations from './app/pages/Layout/LocalQuotes/Seller/MyQuotations';
import MyQuotationArchive from './app/pages/Layout/LocalQuotes/Seller/MyQuotationArchive';
import MyLQArchive from './app/pages/Layout/LocalQuotes/Buyer/MyLQArchive';
import RFQSettings from './app/pages/Layout/LocalQuotes/RFQSettings';
import HomeResource from './app/pages/Resources/Resource';
import FinalResults from './app/pages/Search/Final/Results';
import CompanyResults from './app/pages/Search/Final/CompanyResults';
import RepProfileDelete from './app/pages/Layout/RepProfile/RepProfileDelete';

// class DebugRouter extends BrowserRouter {
//   constructor(props){
//     super(props);
//     console.log('initial history is: ', JSON.stringify(this.history, null,2))
//     this.history.listen((location, action)=>{
//       console.log(
//         `The current URL is ${location.pathname}${location.search}${location.hash}`
//       )
//       console.log(`The last navigation action was ${action}`, JSON.stringify(this.history, null,2));
//     });
//   }
// }

const LFRoutes: React.FC = () => {
  // const { basename } = lfConfig;
    /*const PrivateRoute = ({ component: Component, ...rest }) => (
        <Route {...rest} render={props => (
          fakeAuth.isAuthenticated ? (
            <Component {...props}/>
          ) : (
            <Navigate to='/login' state={props.location} />
          )
        )}/>
      );*/
      
    return (
      // <DebugRouter>
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
          <Suspense fallback={<div><h1>Loading...</h1></div>}>
            <Header />
            <IonContent>
              <Routes>
                  <Route path="/" element={<Home/>} />
                  <Route path="/login" element={<Login/>} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/email-verify" element={<EmailVerify />} />
                  <Route path="/forget-password" element={<ForgetPassword />} />
                  <Route path="/about-us" element={<AboutUs />}  />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />}  />
                  <Route path="/contact-us" element={<ContactUs />} />
                  <Route path="/testing" element={<Testing />} />
                  <Route path="/testing-video" element={<TestingVideo />} />

                  <Route path="/profile/:memid/:repid" element={<Profile />} />
                  <Route path="/preliminary-results" element={<PreResults />} />
                  <Route path="/search-results" element={<FinalResults />} />
                  <Route path="/company-results" element={<CompanyResults />} />
                  
                  <Route path="/press-release/:id" element={<HomePR />} />
                  <Route path="/local-deal/:id" element={<HomeLD />} />
                  <Route path="/resource/:res_type/:id" element={<HomeResource />} />
                  {/* <Route path="/reset-password" element={ResetPassword} /> */}
                  {/* <Route 
                      path="/layout"
                      exact
                      render={props => {
                          return (authValues.authenticated && authValues.isVerified &&  authValues.user) ? <Navigate to="/layout" /> : <Navigate to="/login" />;
                      }}
                      /> */}
                  {/* <Route path="/layout" element={Layout} /> */}
                  <Route path="/layout/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                  <Route path="/layout/profile/:memid/:repid" element={<PrivateRoute><PreviewProfile /></PrivateRoute>} />
                  <Route path="/layout/company-profile" element={<PrivateRoute><CompanyProfile /></PrivateRoute>} />
                  <Route path="/layout/rep-profile/:memid/:repid" element={<PrivateRoute><RepProfile /></PrivateRoute>} />
                  <Route path="/layout/add-newrep" element={<PrivateRoute><NewRep /></PrivateRoute>} />
                  <Route path="/layout/rep-profile-delete" element={<PrivateRoute><RepProfileDelete /></PrivateRoute>} />

                  <Route path="/layout/press-release/:id" element={<PrivateRoute><PressRelease /></PrivateRoute>} />
                  <Route path="/layout/press-releases/" element={<PrivateRoute><PressReleases /></PrivateRoute>} />
                  {/* <Route path="/layout/add-press-release/:id?/:mem_id?/:step?" element={<PrivateRoute><AddPressRelease /></PrivateRoute>} /> */}
                  <Route path="/layout/add-press-release/">
                    <Route path=":id/:mem_id/:step" element={<PrivateRoute><AddPressRelease /></PrivateRoute>} />
                    <Route path="" element={<PrivateRoute><AddPressRelease /></PrivateRoute>} />
                  </Route>

                  <Route path="/layout/resources/:res_type/" element={<PrivateRoute><Resources /></PrivateRoute>} />
                  <Route path="/layout/resources/:res_type/:id" element={<PrivateRoute><Resource /></PrivateRoute>} />
                  {/* <Route path="/layout/add-resource/:res_type/:id?/:mem_id?/:step?" element={<PrivateRoute><AddResource /></PrivateRoute>} /> */}
                  <Route path="/layout/add-resource/:res_type/">
                    <Route path=":id/:mem_id/:step" element={<PrivateRoute><AddResource /></PrivateRoute>} />
                    <Route path="" element={<PrivateRoute><AddResource /></PrivateRoute>} />
                  </Route>

                  <Route path="/layout/deals/local-deal/:id" element={<PrivateRoute><LocalDeal /></PrivateRoute>} />
                  <Route path="/layout/deals/local-deals/" element={<PrivateRoute><LocalDeals /></PrivateRoute>} />
                  {/* <Route path="/layout/deals/add-deal/:id?/:mem_id?/:step?" element={<PrivateRoute><AddDeal /></PrivateRoute>} /> */}
                  <Route path="/layout/deals/add-deal/">
                    <Route path=":id/:mem_id/:step" element={<PrivateRoute><AddDeal /></PrivateRoute>} />
                    <Route path="" element={<PrivateRoute><AddDeal /></PrivateRoute>} />
                  </Route>
                  {/* <Route path="/layout/deals/buy-deal/:id?" element={<PrivateRoute><BuyDeal /></PrivateRoute>} /> */}
                  <Route path="/layout/deals/buy-deal/">
                    <Route path=":id" element={<PrivateRoute><BuyDeal /></PrivateRoute>} />
                    <Route path="" element={<PrivateRoute><BuyDeal /></PrivateRoute>} />
                  </Route>
                  <Route path="/layout/deals/deal-payment/:id" element={<PrivateRoute><DealPayment /></PrivateRoute>} />

                  {/* <Route path="/layout/deals/sponsor-ads/:id" element={<PrivateRoute><LocalDeal /></PrivateRoute>} /> */}
                  <Route path="/layout/ads/sponsor-ads/" element={<PrivateRoute><Ads /></PrivateRoute>} />
                  {/* <Route path="/layout/deals/add-deal/:id?/:mem_id?/:step?" element={<PrivateRoute><AddDeal /></PrivateRoute>} /> */}
                  <Route path="/layout/ads/create-ad/">
                    <Route path=":id/:mem_id/:step" element={<PrivateRoute><CreateAd /></PrivateRoute>} />
                    <Route path="" element={<PrivateRoute><CreateAd /></PrivateRoute>} />
                  </Route>
                  {/* <Route path="/layout/deals/buy-deal/:id?" element={<PrivateRoute><BuyDeal /></PrivateRoute>} /> */}
                  <Route path="/layout/ads/buy-ads/">
                    <Route path=":id" element={<PrivateRoute><BuyAds /></PrivateRoute>} />
                    <Route path="" element={<PrivateRoute><BuyAds /></PrivateRoute>} />
                  </Route>
                  <Route path="/layout/ads/ads-payment/:id/:mem_id/:rep_id" element={<PrivateRoute><AdPayment /></PrivateRoute>} />
                      
                  {/* <Route path="/layout/add-localquote/:id?/:mem_id?/:step?" element={<PrivateRoute><AddLQ /></PrivateRoute>} /> */}
                  <Route path="/layout/add-localquote/">
                    <Route path=":id/:mem_id/:step" element={<PrivateRoute><AddLQ /></PrivateRoute>} />
                    <Route path="" element={<PrivateRoute><AddLQ /></PrivateRoute>} />
                  </Route>
                  {/* <Route path="/layout/view-localquote/:id/:mem_id/:vfrom?" element={<PrivateRoute><ViewLQ /></PrivateRoute>} /> */}
                  <Route path="/layout/view-localquote/:id/:mem_id">
                    <Route path=":vfrom" element={<PrivateRoute><ViewLQ /></PrivateRoute>} />
                    <Route path="" element={<PrivateRoute><ViewLQ /></PrivateRoute>} />
                  </Route>
                  {/* <Route path="/layout/quotation/:id?/:mem_id?/:quote_id?/:step?" element={<PrivateRoute><Quotation /></PrivateRoute>} /> */}
                  <Route path="/layout/quotation/">
                    <Route path=":id/:mem_id/:quote_id/:step" element={<PrivateRoute><Quotation /></PrivateRoute>} />
                    <Route path=":id/:mem_id" element={<PrivateRoute><Quotation /></PrivateRoute>} />
                    <Route path="" element={<PrivateRoute><Quotation /></PrivateRoute>} />
                  </Route>
                  {/* <Route path="/layout/view-quotation/:id?/:mem_id?/:qq_id?/:qq_mem_id?/:vfrom?" element={<PrivateRoute><ViewQuotation /></PrivateRoute>} /> */}
                  <Route path="/layout/view-quotation/">
                    <Route path=":id/:mem_id/:qq_id/:qq_mem_id/:vfrom" element={<PrivateRoute><ViewQuotation /></PrivateRoute>} />
                    <Route path="" element={<PrivateRoute><ViewQuotation /></PrivateRoute>} />
                  </Route>

                  <Route path="/layout/buyer-request-center" element={<PrivateRoute><BuyerRequestCenter /></PrivateRoute>} /> 
                  <Route path="/layout/my-localquotes-archive" element={<PrivateRoute><MyLQArchive /></PrivateRoute>} />
                  <Route path="/layout/notification-settings" element={<PrivateRoute><RFQSettings /></PrivateRoute>} />
                  <Route path="/layout/seller-request-center" element={<PrivateRoute><SellerRequestCenter /></PrivateRoute>} />
                  <Route path="/layout/my-quotations" element={<PrivateRoute><MyQuotations /></PrivateRoute>} />
                  <Route path="/layout/my-quotations-archive" element={<PrivateRoute><MyQuotationArchive /></PrivateRoute>} />
                  {/* <Route path="/layout/add-qq/:rfqType/:id?/:mem_id?/:step?" element={<AddQQ />} /> */}
                  
        
                  <Route path="*" element={<NoPage />} />
              </Routes>
          </IonContent>
          <Footer/>
        </Suspense>  
      </BrowserRouter>
    // </DebugRouter>
    );
}

export default LFRoutes;

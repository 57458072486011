import React, { useEffect } from 'react';
import { IonApp } from '@ionic/react';
import { setupIonicReact } from '@ionic/react';
import LFRoutes from './Routes';
import SEO from './SEO';

setupIonicReact({
  mode: 'md'
});

const App: React.FC = () => {

  return (
    // <IonApp>
    //     <LFRoutes />
    // </IonApp>
    <div>
      <SEO
        title="Your SSR App"
        description="A description of your app for SEO purposes."
        keywords="react, ssr, seo, example"
      />
      {/* Your other React components go here */}
      <IonApp>
         <LFRoutes />
      </IonApp>
    </div>
  );
}

export default App;

import { IonText } from '@ionic/react';
import React from 'react';
interface PropsInterface{
    is_active: number;
    type: string;
    converted?: number| any;
    text?: boolean;
}

const Status: React.FC<PropsInterface> = (props: PropsInterface) => {
    let color = 'blue';
    let status = 'Pending';
    let text = ''
    if(['press_release', 'local_deal'].includes(props.type)){
        if(+(props.is_active) === 1 ){
            color = 'iconbg';
            status = 'Active';
        }else if(+(props.is_active) === 2 ){
            color = 'error';
            status = 'Suspended';
        }else if(+(props.is_active) === 3 ){
            color = 'gray';
            status = 'Expired';
        }
        return (
             <i className={`fa fa-circle-o fa-lg fw-bold ${color}`}  aria-hidden="true" title={`${status}`}></i>
        );
    }else if(['resources'].includes(props.type)){
        if( +(props.is_active) === 1 && +(props.converted) === 1 ){
            color = 'iconbg';
            status = 'Active';
        }else if(+(props.is_active) === 2 && +(props.converted) === 1 ){
            color = 'error';
            status = 'Suspended';
        }
        return (
             <i className={`fa fa-circle-o fa-lg fw-bold ${color}`}  aria-hidden="true" title={`${status}`}></i>
        );
    }else if(['sponsor_ads'].includes(props.type)){
        if(+(props.is_active) === 1 ){
            color = 'green';
            status = 'Active';
            text = 'Active';
        }else if(+(props.is_active) === 2 ){
            color = 'gray';
            status = 'Cancelled';
            text = 'Cancelled';
        }else if(+(props.is_active) === 3 ){
            color = 'error';
            status = 'Suspended';
            text = 'Suspended';
        }else if(+(props.is_active) === 4 ){
            color = 'warn';
            status = 'Expired';
            text = 'Expired';
        }else{
            color = 'status_pending';
            status = 'Pending';
            text = 'Open';
        }
        return (
            <span className="status_pending">
                <i className={`fa fa-circle-o fa-lg fw-bold ${color}`}  aria-hidden="true" title={`${status}`}></i> 
                {text && <IonText className='fs-11 pl-1'>{text}</IonText>}
            </span>
        );
    }else{
        return (<></>);
    }
};

export default Status;
import { IonCard, IonCardContent, IonCardHeader, IonRouterLink, IonRow, IonCol, IonButtons, IonItem, IonLabel, IonGrid, IonThumbnail, IonFab, IonFabButton, IonIcon, IonModal } from '@ionic/react';
import { chatboxEllipsesOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import '../LocalQuotes.scss';
import { lfConfig } from '../../../../../Constants';
import CommonService from '../../../../shared/services/CommonService';
import RFQStatus from '../../../../components/Common/RFQStatus';
import { Browser } from '@capacitor/browser';
import Chat from '../../../../components/Modal/Chat/Chat';

interface Props {
    qq: any
}

let initialValues = {
    isOpen: false,
    title: '',
    qq_type: '',
    bid: 0,
    brep_id: 0,
    bmem_id: 0,
    sid: 0,
    srep_id: 0,
    smem_id: 0
};

const SRContent: React.FC<Props> = ({qq}) => {
    const { basename } = lfConfig;
    const { apiBaseURL } = lfConfig;
    const authUser = useSelector( (state:any) => state.auth.data.user);
    const repImage = (Object.keys(qq).length > 0 && qq.profile_image) ? `${apiBaseURL}uploads/member/${qq.mem_id}/${qq.rep_id}/${qq.profile_image}` : `${basename}/assets/img/avatar.svg`;
    const [showChatModal, setShowChatModal] = useState(initialValues);

    const openProfileLink = async (mem_id: number, rep_id: number) => {
        await Browser.open({ url: `/profile/${mem_id}/${rep_id}` });
    };

    const handleChatFn = (bid: number, brep_id: number, bmem_id: number, sid: number, srep_id: number, smem_id: number) => {
        // console.log(authUser);
        // console.log(bid, brep_id, bmem_id, sid, srep_id, smem_id);
        setShowChatModal({ 
            ...showChatModal, 
            isOpen: true,
            title: 'Message Center',
            qq_type: 'seller',
            bid, // Buyer QQ ID
            brep_id, // Buyer Rep ID
            bmem_id, // Buyer Mem ID
            sid, // Seller QQ ID
            srep_id, // Seller Rep ID
            smem_id, // Seller Mem ID
        });
    }

    return (<IonCard className="card-center mt-3 mb-1">
        
        <IonCardContent>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonItem className="ion-no-padding" lines="none" >
                            <IonThumbnail slot="start" color="appbg">
                            {/* <IonRouterLink href={`/member/${item.pr_mem_id}/${item.pr_id}`}> */}
                                <img src={repImage} alt="Rep Profile" onError={() => CommonService.onImgErr('profile')} />
                            {/* </IonRouterLink> */}
                            </IonThumbnail>
                            
                            <IonLabel className="ion-text-wrap">
                                <h2>{qq.p_title}</h2>
                                {/* <h3 className="cursor anchor-link" onClick={() => setShowPopover({status: true, qq: qq})}>{`${qq.firstname} ${qq.lastname}`}</h3> */}
                                <h3 className="cursor anchor-link" onClick={()=> openProfileLink(qq.mem_id, qq.rep_id) }>
                                    {`${qq.firstname} ${qq.lastname}`}
                                </h3>    
                                <IonFab vertical="bottom" horizontal="end" >
                                    <IonFabButton color="appbg" size="small" onClick={()=>handleChatFn(qq.id, qq.rep_id, qq.mem_id, 0, authUser.repID, authUser.ID)}> 
                                        <IonIcon icon={chatboxEllipsesOutline} size="small" />
                                    </IonFabButton>
                                </IonFab>
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem className="ion-no-padding" lines="none" >
                            <IonLabel>
                                <p><span className="fw-bold">Date of Request : </span>{CommonService.dateFormat(qq.quotation_date)}</p>
                                <p><span className="fw-bold">Response Deadline : </span>{CommonService.dateFormat(qq.quotation_req_date)}</p>
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                </IonRow>
            </IonGrid>
            
        </IonCardContent>
        <IonCardHeader className="p-1" color="titlebg">
            <IonRow>
                <IonCol className="d-flex align-items-center">
                    Status: <RFQStatus status={+(qq.is_active)} plainText={false} frontend={false}/>
                </IonCol>
                <IonCol>
                    <IonButtons>
                    <IonRouterLink slot="start" color="appbg" href={`/layout/view-localquote/${qq.id}/${qq.mem_id}/sellers`}>
                        View RFQ</IonRouterLink>
                    </IonButtons>
                </IonCol>
            </IonRow>
        </IonCardHeader>
        <IonModal backdropDismiss={false} isOpen={showChatModal.isOpen} className='view-modal-wrap'>
            { showChatModal.isOpen === true &&  <Chat
                showChatModal={showChatModal}
                setShowChatModal={setShowChatModal} 
           /> }
        </IonModal>
    </IonCard>);
};

export default SRContent;

import { IonContent, IonPage, IonActionSheet, IonAlert, IonPopover, IonText, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle } from '@ionic/react';
import React, {useCallback, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { close, pencilOutline, archiveOutline, trashOutline } from 'ionicons/icons';
import { isPlatform } from '@ionic/react';
import '../LocalQuotes.scss';
import { useDispatch, useSelector } from 'react-redux';
import * as uiActions from '../../../../store/reducers/ui';
import * as qqActions from '../../../../store/reducers/dashboard/qq';
import CoreService from '../../../../shared/services/CoreService';
import SRContent from './SRContent';
import ListSkeleton from '../../../../components/Skeleton/ListSkeleton';
import NoData from '../../../../components/Common/NoData';
import { nanoid } from 'nanoid';
import Breadcrumbs from '../../../../components/Header/Breadcrumbs';

const SellerRequestCenter: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const skeleton = useSelector( (state:any) => state.ui.skeleton);
  const authUser = useSelector( (state:any) => state.auth.data.user);
  const qqs = useSelector( (state:any) => state.qq.localQuotes);
  const [showAlert, setShowAlert] = useState({status: false, id: '', mem_id: '' });
  const [showActionSheet, setShowActionSheet] = useState<any>({status: false, qq: null});
  // const [showPopover, setShowPopover] = useState<any>({status: false, qq: null});
  let actionsheetButtons: any = [];

  const onCallbackFn = useCallback((res: any) => {
    if(res.status === 'SUCCESS'){
      dispatch(qqActions.setQQs({ data: res.data }));
    }
    dispatch(uiActions.setShowSkeleton({ skeleton: false }));
  }, [dispatch]);

  useEffect(() => { 
    if( authUser && authUser.ID ){
      dispatch(uiActions.setShowSkeleton({ skeleton: true }));
      // dispatch(uiActions.setShowLoading({ loading: true }));
      const data = {
        action: 'get_all_buyer_qq_by_cat',
        memID: authUser.accType === 'sub'? authUser.pmemID: authUser.ID,
        repID: authUser.repID
      };
      CoreService.onPostFn('qq_update', data, onCallbackFn);
    }
  }, [dispatch, onCallbackFn, authUser]); 

  const onCommonCb = useCallback((res: any) => {
    if(res.status === 'SUCCESS'){
      dispatch(qqActions.setQQs({ data: res.data }));
    }
    dispatch(uiActions.setShowSkeleton({ skeleton: false }));
    dispatch(uiActions.setShowToast({ isShow: true, status: res.status, message: res.message }));
  }, [dispatch]);

  const onDeleteFn = (id: number, mem_id: number) => {
    dispatch(uiActions.setShowSkeleton({ skeleton: true }));
    const fd = {
        action: 'qq_delete',
        memID: mem_id,
        repID: authUser.repID,
        formID: id
    };
    CoreService.onPostFn('qq_update', fd, onCommonCb);
  } 
  // For Archive
  const onMoveArchiveFn = (id: number, mem_id: number) => {
    dispatch(uiActions.setShowSkeleton({ skeleton: true }));
    const fd = {
        action: 'qq_move_archive',
        memID: mem_id,
        repID: authUser.repID,
        formID: id
    };
    CoreService.onPostFn('qq_update', fd, onCommonCb);
  } 
  if(showActionSheet.qq && Object.keys(showActionSheet.qq).length > 0 && ![3,4,5,6].includes(parseInt(showActionSheet.qq.is_active))){
    actionsheetButtons.push({
      text: 'Edit',
      icon: pencilOutline,
      handler: () => {
        console.log('Edit clicked');
        navigate(`/layout/add-localquote/${showActionSheet.qq.id}/${showActionSheet.qq.mem_id}/1`);
      }
    }, {
      text: 'Delete',
      role: 'destructive',
      icon: trashOutline,
      handler: () => {
        setShowAlert({status: true, id: showActionSheet.qq.id, mem_id: showActionSheet.qq.mem_id });
      }
    });
  }
  if(showActionSheet.qq && Object.keys(showActionSheet.qq).length > 0 && [1,2,3,4,5,6].includes(parseInt(showActionSheet.qq.is_active))){
    actionsheetButtons.push({
      text: 'Move to Archive',
      icon: archiveOutline,
      handler: () => {
        onMoveArchiveFn( +(showActionSheet.qq.id), +(showActionSheet.qq.mem_id));
      }
    });
  }

  actionsheetButtons.push({
    text: 'Cancel',
    icon: close,
    role: 'cancel',
    handler: () => {}
  });

  return (
    <IonPage className="rfq-page mb-4">
      <Breadcrumbs step1={{label:'Submit Quotes', link: ''}} />
      <IonToolbar>
        <IonTitle className="page-title">View Local Quote Requests</IonTitle>
      </IonToolbar>
      { !skeleton.showSkeleton && qqs ? ( 
        <IonContent>
          <div>
            { qqs.map((qq: any, index: number) => {
              return (<SRContent 
                qq={qq} 
                key={nanoid()}
                />)
            })}  
          </div>
          <NoData dataArr={qqs} htmlText={`No requests found.`} />
          <IonAlert
            isOpen={showAlert.status}
            onDidDismiss={() => setShowAlert({status: false, id: '', mem_id: '' })}
            header={'Confirm!'}
            message={`Are you sure want to delete this QQ?`}
            inputs={[
              {
                name: 'reason',
                type: 'text',
                id: 'delete-reason',
                value: '',
                placeholder: 'Enter delete reason please?'
              }
            ]}
            buttons={[
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'primary'
              },
              {
                text: 'Okay',
                cssClass: 'appbg',
                handler: () => {
                  onDeleteFn( +(showActionSheet.qq.id), +(showActionSheet.qq.mem_id));
                }
              }
            ]}
          />
          {/* <IonActionSheet
            isOpen={showActionSheet.status}
            onDidDismiss={() => setShowActionSheet({status: false, qq: null})}
            cssClass='my-custom-class'
            buttons={actionsheetButtons}
          />
          */}
          
      </IonContent>) : ( <ListSkeleton /> )}
  
    </IonPage>
  );
};

export default SellerRequestCenter;

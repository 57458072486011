import { IonContent, IonPage } from '@ionic/react';
import './Dashboard.scss';
import { useSelector } from 'react-redux';
import ProfileSetup from './ProfileSetup';
import Notification from './Notification';
import Breadcrumbs from '../../../components/Header/Breadcrumbs';

const Dashboard: React.FC = () => {
  const authValues = useSelector( (state:any) => state.auth.data);
  
  return (
    <IonPage className="dashboard-page">
      <Breadcrumbs step1={{label:'Dashboard', link: ''}} />
      <IonContent>
      { +(authValues.user.mem_level) === 0 &&  
         <ProfileSetup />
      }
      { +(authValues.user.mem_level) !== 0 &&  
         <Notification  />
      }
      
      </IonContent> 
    </IonPage>
  );
};

export default Dashboard;

import {
    IonText,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonList
} from '@ionic/react';
import React from 'react';
import './Profile.scss';
import { useSelector } from 'react-redux';

const Buscats: React.FC = () => {
    const repProfile = useSelector( (state:any) => state.rep.repProfile);
    const busCategory = useSelector( (state:any) => state.rep.buscats);

    return (<>
        { Object.keys(repProfile).length > 0 && ( (busCategory && busCategory.length > 0)) && 
        <IonCard className="buscat-section-wrap card-center mt-4 mb-4">
            <IonCardHeader color="titlebg">
                <IonCardTitle className="card-custom-title">
                    <span>Product & Services</span>
                </IonCardTitle>
            </IonCardHeader>
              
            <IonCardContent>
                { busCategory && busCategory.length > 0 && <IonList className="buscat-section-content">
                    {/* <IonListHeader>
                        <IonLabel className="fs-16">Categories</IonLabel>
                    </IonListHeader> */}
                    { busCategory.map((item: any)=> {
                        return (<div className="pl-3 pb-2" key={item.id}>
                            <p>
                                <IonText>
                                    {item.catname}<i className="fa fa-arrow-right mx-2 gray-medium" aria-hidden="true"></i>{item.sub_catname}
                                </IonText>
                            </p>
                            { item.keywords && <p><strong>Keywords: </strong>{item.keywords}</p> }
                        </div>)
                    })}
                </IonList>}
            </IonCardContent>
        </IonCard>
        }
    </>);
};
  
export default Buscats;
  
import { IonContent, IonPage, IonText, IonPopover, IonIcon, IonButtons, IonButton, IonToolbar, IonTitle } from '@ionic/react';
import React, {useCallback, useState, useEffect} from 'react';
import { close} from 'ionicons/icons';
import { isPlatform } from '@ionic/react';
import '../LocalQuotes.scss';
import { useDispatch, useSelector } from 'react-redux';
import * as uiActions from '../../../../store/reducers/ui';
import * as qqActions from '../../../../store/reducers/dashboard/qq';
import CoreService from '../../../../shared/services/CoreService';
import BRContentArchive from './BRContentArchive'; 
import ListSkeleton from '../../../../components/Skeleton/ListSkeleton';
import NoData from '../../../../components/Common/NoData';
import { nanoid } from 'nanoid';
import Breadcrumbs from '../../../../components/Header/Breadcrumbs';

const MyLQArchive: React.FC = () => {
  const dispatch = useDispatch();
  const skeleton = useSelector( (state:any) => state.ui.skeleton);
  const authUser = useSelector( (state:any) => state.auth.data.user);
  const qqs = useSelector( (state:any) => state.qq.localQuotes);

  const onCallbackFn = useCallback((res: any) => {
    if(res.status === 'SUCCESS'){ 
      dispatch(qqActions.setQQs({ data: res.data }));
    }
    dispatch(uiActions.setShowSkeleton({ skeleton: false }));
  }, [dispatch]);

  useEffect(() => { 
    if( authUser && authUser.ID ){
      dispatch(uiActions.setShowSkeleton({ skeleton: true }));
      // dispatch(uiActions.setShowLoading({ loading: true }));
      const data = {
        action: 'get_localquotes_archive',
        memID: authUser.ID
      };
      CoreService.onPostFn('qq_update', data, onCallbackFn);
    }
  }, [dispatch, onCallbackFn, authUser]); 

  return (
    <IonPage className="rfq-page mb-4">
      { !skeleton.showSkeleton && qqs ? (<>
        <Breadcrumbs step1={{label:'Get Quotes', link: '/layout/buyer-request-center'}} step2={{label:'RFQ Archives', link: ''}} />
        <IonContent>
          <IonToolbar>
            <IonTitle className="page-title">My LocalQuote Archive</IonTitle>
          </IonToolbar>
          <div>
            { qqs.map((qq: any, index: number) => {
              return (<BRContentArchive 
                qq={qq} 
                key={nanoid()} 
                // setShowActionSheet={setShowActionSheet}
                // setShowDeleteModal={setShowDeleteModal}
                // onAwardedFn={onAwardedFn} 
                />)
            })} 
          </div>
          <NoData dataArr={qqs} htmlText={`No requests found.`} />
          
      </IonContent>
      </>) : ( <ListSkeleton /> )}
    </IonPage>
  );
};

export default MyLQArchive;
